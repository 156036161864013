import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useContext, useEffect, useState } from 'react';
import NhimaTable from './NhimaTable';
import NhimaHeader from './Header';
import { NhimaProps } from './types';
import { toastText } from 'utils/utils';
import UpdateNhimaComponent from './UpdateNhima';
import { SideDrawerWrapper } from 'components/Global';
import { nhimaApi } from 'Api/nhima';
import HistoryModal from './NhimaHistoryModal';

const NhimaComponent = (props: NhimaProps) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [isLoading, setIsLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [drawerAnimation, setDrawerAnimation] = useState<boolean>(false);
	const [isSideDrawerOpen, setSideDrawerOpen] = useState<boolean>(false);
	const [latestNhimaDetails, setLatestNhimaDetails] = useState<any>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [allNhimaData, setAllNhimaData] = useState<any>([]);
	const [isHistoryLoading, setIsHistoryLoading] = useState(false);

	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const sidebarDrawerOpen = () => {
		setDrawerAnimation(true);
		setSideDrawerOpen(true);
	};

	const removeDrawerFromDom = () => {
		setSideDrawerOpen(false);
	};

	const closeDrawerByAnimation = () => {
		setDrawerAnimation(false);
	};
	const showModal = () => {
		setIsModalOpen(true);
	};
	const closeModal = () => {
		setIsModalOpen(false);
	};

	const fetchLatestNhimaData = async () => {
		try {
			setIsLoading(true);

			const nhimaData = await nhimaApi.getLatestNhima();
			setLatestNhimaDetails(nhimaData.data.data);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText(
					'Something went wrong in fetching nhima Details',
					'error'
				);
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setIsLoading(false);
		}
	};
	const fetchAllNhimaDetails = async () => {
		try {
			const query = {
				page: currentPage,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
			};

			setIsHistoryLoading(true);
			const allData = await nhimaApi.getNhimaHistory(query);
			setAllNhimaData(allData?.data?.data);
			setTotalRecords(allData?.data?.total);
		} catch (error: any) {
			if (error.response?.data?.error?.code !== 103) {
				toastText('Something went wrong in fetching history', 'error');
			} else {
				toastText(error.response?.data?.error?.message, 'error');
			}
		} finally {
			setIsHistoryLoading(false);
		}
	};
	useEffect(() => {
		fetchLatestNhimaData();
	}, []);

	useEffect(() => {
		fetchAllNhimaDetails();
	}, [pageSize, currentPage, sortOrder, sortField]);

	return (
		<>
			<div>
				<NhimaHeader
					sidebarDrawerOpen={sidebarDrawerOpen}
					NhimaExists={latestNhimaDetails.length > 0}
						isLoading={isLoading}
					fetchLatestNhimaData={fetchLatestNhimaData}
				/>
				<NhimaTable
					nhimaLatestData={latestNhimaDetails}
					isLoading={isLoading || isHistoryLoading}
					showModal={showModal}
					totalRecords={totalRecords}
				/>
			</div>
			{isSideDrawerOpen && (
				<SideDrawerWrapper
					isOpen={drawerAnimation}
					removeDrawerFromDom={removeDrawerFromDom}
					closeDrawerByAnimation={closeDrawerByAnimation}
					headerTitle={
						latestNhimaDetails.length > 0
							? 'Edit NHIMA'
							: 'Add NHIMA'
					}
					position="right"
					width="half"
				>
					<UpdateNhimaComponent
						closeDrawerByAnimation={closeDrawerByAnimation}
						fetchLatestNhimaData={fetchLatestNhimaData}
						fetchAllNhimaDetails={fetchAllNhimaDetails}
					/>
				</SideDrawerWrapper>
			)}
			{isModalOpen && (
				<HistoryModal
					closeModal={closeModal}
					open={isModalOpen}
					totalRecords={totalRecords}
					pageSize={pageSize}
					currentPage={currentPage}
					tableChangeHandler={tableChangeHandler}
					allNhimaData={allNhimaData}
					isLoading={isHistoryLoading}
					fetchAllNhimaDetails={fetchAllNhimaDetails}
				/>
			)}
		</>
	);
};

export default NhimaComponent;
