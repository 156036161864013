import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	CheckCircleFilled,
	InboxOutlined,
	SearchOutlined,
	SyncOutlined,
	WarningOutlined,
} from '@ant-design/icons';
import {
	Button,
	Modal,
	Result,
	Select,
	Space,
	Steps,
	Table,
	Tooltip,
} from 'antd';
import Upload, { UploadFile } from 'antd/es/upload';
import Dragger from 'antd/es/upload/Dragger';
import { employeeApi } from 'Api/employee';
import { Loader } from 'components/Global';
import SearchComponent from 'components/Global/SearchComponent';
import { useState } from 'react';
import { DownloadSvg, UploadSvg } from 'utils/svgs';
import { parseCSV, toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';

const { Step } = Steps;

const statusOptions = [
	{
		label: 'All Status',
		value: '',
	},
	{
		label: 'Active',
		value: 'ACTIVE',
	},
	{
		label: 'Suspended',
		value: 'SUSPENDED',
	},
	{
		label: 'Redundant',
		value: 'REDUNDANT',
	},
	{
		label: 'Retired',
		value: 'RETIRED',
	},
	{
		label: 'Reassigned',
		value: 'REASSIGNED',
	},
	{
		label: 'Dismissed',
		value: 'DISMISSED',
	},
	{
		label: 'Deceased',
		value: 'DECEASED',
	},
];

const steps = [
	{
		title: 'Upload CSV',
	},
	{
		title: 'Validation Results',
	},
	{
		title: 'Correct Data',
	},
	{
		title: 'Confirmation',
	},
];

const staffCodeOptions = [
	{
		label: 'All Staff Code',
		value: '',
	},
	{
		label: 'Hourly',
		value: 'HOURLY',
	},
	{
		label: 'Monthly',
		value: 'MONTHLY',
	},
];

type Props = {
	searchValue: string;
	handleSearch: (value: string) => void;
	statusFilterValue: string;
	handleStatusFilter: (value: string) => void;
	handleSearchApi: (value: string) => void;
	staffCodeFilterValue: string;
	handleStaffCodeFilter: (value: string) => void;
	fetchRecords: any;
	fetchEmployees: any;
	isLoading: any;
};

const EmployeeHeader = (props: Props) => {
	const {
		searchValue,
		handleSearch,
		statusFilterValue,
		handleStatusFilter,
		handleSearchApi,
		handleStaffCodeFilter,
		staffCodeFilterValue,
		fetchEmployees,
		isLoading,
	} = props;

	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [isUploadLoading, setIsUploadLoading] = useState(false);

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [validationResults, setValidationResults] = useState([]);

	const [finalData, setFinalData] = useState([]);

	const [isErrorModal, setIsErrorModal] = useState(false);
	const [errorData, setErrorData] = useState<any>(null);

	const [currentStep, setCurrentStep] = useState(0);
	const [isImportLoading, setIsImportLoading] = useState(false);

	const next = async () => {
		if (currentStep == 1) {
			const data = validationResults.filter((item: any) => item._isValid);
			setFinalData(data);
		}
		if (currentStep == 2) {
			await importEmployees(finalData);
		} else {
			setCurrentStep((prev: any) => {
				return Math.min(prev + 1, steps.length - 1);
			});
		}
	};

	const prev = () => {
		setCurrentStep((prev) => Math.max(prev - 1, 0));
	};

	const columns = [
		...Object.keys(validationResults[0] || {})
			.filter((key) => !key.startsWith('_'))
			.map((key) => ({
				title: key,
				dataIndex: key,
				key: key,
				width: 150,
				ellipsis: true, // Truncate if text overflows
				className: 'bg-table',
				render: (text: string) => (
					<div style={{ minWidth: 150 }}>{text}</div>
				),
			})),
		{
			title: 'Status',
			key: '_validationStatus',
			width: 80,
			render: (_: any, record: any) =>
				record._isValid ? (
					<div
						className="text-green-500 bg-table"
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							color: 'green',
						}}
					>
						<CheckCircleOutlined />
					</div>
				) : (
					<div
						className="text-red-500 bg-table"
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							color: 'red',
						}}
					>
						<CloseCircleOutlined />
					</div>
				),
		},
		{
			title: 'Validation',
			key: '_errors',
			width: 105,
			render: (_: any, record: any) => {
				return record._validationErrors ? (
					<p
						onClick={() => {
							setIsErrorModal(true);
							setErrorData(record);
						}}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							color: 'orange',
						}}
						className="pointer"
					>
						<WarningOutlined />
					</p>
				) : null;
			},
		},
	];

	const downloadCsv = async () => {
		try {
			const response = await employeeApi.downloadEmployeeCsvTemplate();
			const url = window.URL.createObjectURL(
				new Blob([response.data], {
					type: 'text/csv',
				})
			);

			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `employees.csv`);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			toastText(
				'Something went wrong in downloading csv template',
				'error'
			);
		}
	};

	const handleUpload = async (file: any) => {
		const reader = new FileReader();
		reader.onload = async (e: any) => {
			try {
				setIsUploadLoading(true);
				const csvText = e.target.result;

				// Parse CSV data into an array of objects
				const parsedData = parseCSV(csvText);

				// Call the API with parsed data
				const validatedData: any =
					await employeeApi.validateDataFromAPI({
						employees: parsedData,
					});

				// Update state with validation results
				setValidationResults(validatedData.data?.data); // Assuming `data` is the array from the API response
				setIsModalVisible(true);

				setCurrentStep(1);

				// Handle the overall validation flag
				// if (!validatedData.allValid) {				}
			} catch (error) {
				console.error(
					'Error during file upload and validation:',
					error
				);
				toastText('Failed to process file. Please try again.', 'error');
			} finally {
				setIsUploadLoading(false);
			}

			// try {
			// 	const csvText = e.target.result;
			// 	console.log('csvText: ', csvText);
			// 	const parsedData = parseCSV(csvText);
			// 	console.log('parsedData: ', parsedData);
			// 	const validatedData = parsedData.map(validateEmployee);
			// 	console.log('validatedData: ', validatedData);

			// 	setValidationResults(validatedData);
			// 	setIsModalVisible(true);
			// } catch (error) {
			// 	console.error('CSV parsing error:', error);
			// 	toastText('Failed to parse CSV file', 'error');
			// }
		};

		reader.onerror = () => {
			toastText('File reading failed', 'error');
		};

		reader.readAsText(file);

		// Prevent upload
		return false;
	};

	const importEmployees = async (data: any) => {
		try {
			setIsImportLoading(true);
			const response = await employeeApi.importEmployees({
				employees: data,
			});
			toastText('Employees imported successfully', 'success');
			setCurrentStep(3);
		} catch (err) {
			toastText('Failed to import employees', 'error');
		} finally {
			setIsImportLoading(false);
		}
	};

	const handleModalClose = () => {
		setIsModalVisible(false);
		setErrorData(false);
		setFileList([]);
		setCurrentStep(0);
		setFinalData([]);
		setErrorData([]);
		setIsErrorModal(false);
		setIsImportLoading(false);
		setIsUploadLoading(false);
	};

	const handleImportMore = () => {
		setErrorData(false);
		setFileList([]);
		setCurrentStep(0);
		setFinalData([]);
		setErrorData([]);
		setIsErrorModal(false);
		setIsImportLoading(false);
		setIsUploadLoading(false);
	};

	return (
		<>
			<div className={styles['employee-header']}>
				<Space>
					<SearchComponent
						className={styles['employee-header-item']}
						placeHolder="Search employee..."
						prefixIcon={<SearchOutlined />}
						handleChange={handleSearch}
						handleChangeApi={handleSearchApi}
						value={searchValue}
						size="large"
					/>
				</Space>
				<Space>
					<Select
						className={styles['employee-header-item']}
						value={statusFilterValue}
						options={statusOptions}
						onChange={(value) => {
							handleStatusFilter(value);
						}}
						size="large"
						placeholder="Select Status"
					/>
				</Space>
				<Space>
					<Select
						className={styles['employee-header-item']}
						value={staffCodeFilterValue}
						options={staffCodeOptions}
						onChange={(value) => {
							handleStaffCodeFilter(value);
						}}
						size="large"
						placeholder="Select Staff Code"
					/>
				</Space>
				<div className={styles['refresh-buttons']}>
					<Tooltip title="Refresh all the Employee Details">
						<SyncOutlined
							style={{
								fontSize: '20px',
								cursor: isLoading ? 'not-allowed' : 'pointer',
								color: '#584495',
								transition: 'transform 0.3s',
								marginRight: '10px',
							}}
							onClick={!isLoading ? fetchEmployees : undefined}
						/>
					</Tooltip>
				</div>
				{/* <p className={styles['csv-button']} onClick={downloadCsv}>
					<DownloadSvg />
				</p>
				<p
					className={styles['csv-button']}
					onClick={() => setIsModalVisible(true)}
				>
					<UploadSvg />
				</p> */}
			</div>

			<Modal
				visible={isModalVisible}
				onCancel={handleModalClose}
				width="1400px"
				footer={null}
				rootClassName="uploadCsvModal"
				closable={false}
				style={{
					maxHeight: 'calc(100vh - 200px)', // Fixed height for the modal
					display: 'flex',
					flexDirection: 'column',
					// overflowY: 'scroll',
				}}
			>
				<div>
					<Steps current={currentStep}>
						{steps.map((step, index) => (
							<Step key={index} title={step.title} />
						))}
					</Steps>

					<div
						style={{
							marginTop: '20px',
							marginBottom: '20px',
							padding: '20px',
							border: '1px solid #d9d9d9',
							borderRadius: '5px',
							flex: 1,
							overflow: 'hidden',
							display: 'flex',
							flexDirection: 'column',
							minHeight: '200px',
							justifyContent: 'center',
						}}
					>
						{currentStep === 0 && (
							<div className={styles['document-upload']}>
								{isUploadLoading ? (
									<Loader />
								) : (
									<Dragger
										name="file"
										multiple={false}
										accept=".csv"
										fileList={fileList}
										beforeUpload={(file) => {
											const isCSV =
												file.type === 'text/csv' ||
												file.name
													.toLowerCase()
													.endsWith('.csv');

											if (!isCSV) {
												toastText(
													'You can only upload CSV files!',
													'error'
												);
												return Upload.LIST_IGNORE;
											}

											handleUpload(file);
											return false;
										}}
										onChange={(info) => {
											let newFileList = [
												...info.fileList,
											];

											// Limit to one file
											newFileList = newFileList.slice(-1);

											setFileList(newFileList);
										}}
									>
										<p className="ant-upload-drag-icon">
											<InboxOutlined />
										</p>
										<p className="ant-upload-text">
											Click or drag CSV file to this area
											to upload
										</p>
										<p className="ant-upload-hint">
											Support for a single CSV file upload
										</p>
									</Dragger>
								)}
							</div>
						)}

						{currentStep === 1 && (
							<Table
								columns={columns}
								dataSource={validationResults ?? []}
								scroll={{ x: 'max-content', y: 500 }}
								pagination={false}
								size="middle"
								bordered
								tableLayout="fixed"
							/>
						)}

						{currentStep === 2 && (
							<Table
								columns={columns.slice(0, -2)}
								dataSource={finalData ?? []}
								scroll={{ x: 'max-content', y: 500 }}
								pagination={false}
								loading={isImportLoading}
							/>
						)}

						{currentStep === 3 && (
							<Result
								status="success"
								title="Employees Imported Successfully!"
								subTitle={`${finalData.length} employees imported`}
								icon={
									<CheckCircleFilled
										style={{ color: '#584495' }}
									/>
								}
								extra={[
									<Button
										type="primary"
										key="console"
										onClick={handleImportMore}
									>
										Import More
									</Button>,
									<Button
										key="buy"
										onClick={handleModalClose}
									>
										Cancel
									</Button>,
								]}
							/>
						)}
					</div>

					{currentStep !== 3 && (
						<div
							style={{
								marginTop: '20px',
								display: 'flex',
								justifyContent: 'end',
								gap: '15px',
							}}
						>
							<Button onClick={handleModalClose}>Cancel</Button>
							{currentStep !== 0 && (
								<Button
									disabled={
										currentStep === 0 ||
										isImportLoading ||
										isUploadLoading
									}
									onClick={prev}
									type="primary"
								>
									Previous
								</Button>
							)}
							{fileList.length > 0 && (
								<Button
									disabled={
										currentStep === steps.length - 1 ||
										isImportLoading ||
										isUploadLoading
									}
									type="primary"
									onClick={next}
								>
									{currentStep === 2
										? 'Import Employees'
										: 'Next'}
								</Button>
							)}
						</div>
					)}
				</div>
			</Modal>

			{errorData && isErrorModal && (
				<Modal
					visible={isErrorModal}
					onCancel={() => {
						setErrorData(null);
						setIsErrorModal(false);
					}}
					width="90%"
					footer={null}
				>
					<ul
						className="error-list"
						style={{
							listStyleType: 'none',
							padding: '10px',
							borderRadius: '5px',
							border: '1px solid #d4d4d4',
							color: '#721c24',
							fontFamily: 'Arial, sans-serif',
							// height: '550px',
							overflowY: 'auto',
						}}
					>
						{Object.entries(errorData._validationErrors).map(
							([field, error]: any) => (
								<li
									key={field}
									style={{
										padding: '8px 12px',
										borderBottom: '1px solid #d4d4d4',
									}}
								>
									<span
										style={{
											fontWeight: 'bold',
											color: '#b02a2a',
										}}
									>
										{field}
									</span>{' '}
									:{' '}
									<span
										style={{
											fontStyle: 'italic',
											color: 'black',
										}}
									>
										{error}
									</span>
								</li>
							)
						)}
					</ul>
				</Modal>
			)}
		</>
	);
};

export default EmployeeHeader;
