import { Form, Input, Select } from 'antd';
import { postApi, putApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import { FC, useContext, useEffect, useState } from 'react';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';
import { SideDrawerBodyProps } from './types';
import { PermissionContext } from 'components/Global/AuthLayout';
import axios from 'axios';
import { currencyData } from 'constants/CurrencyData';
import CurrencyModal from '../Currency';
const AddCompanyBody: FC<SideDrawerBodyProps> = (props) => {
	const permissionContext = useContext(PermissionContext);

	const [loading, setLoading] = useState<boolean>(false);
	const { closeDrawerByAnimation, fetchCompanyData, editSelectedUser } =
		props;
	const [currencies, setCurrencies] = useState<{
		[key: string]: string;
	}>({});

	const buttons = [
		{
			text: `Cancel`,
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: loading,
			onclick: () => {
				closeDrawerByAnimation();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
		{
			text: 'Save',
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
	];

	// useEffect(() => {
	// 	const fetchCurrencies = async () => {
	// 		try {
	// 			const response = await axios.get(
	// 				'https://openexchangerates.org/api/currencies.json'
	// 			);
	// 			setCurrencies(response.data);
	// 		} catch (error) {
	// 			console.error('Error fetching currencies:', error);
	// 		}
	// 	};

	// 	fetchCurrencies();
	// }, []);

	const handleSubmit = async (values: any) => {
		if (editSelectedUser) {
			let data: any = {
				companyId: editSelectedUser?.id,
				...values,
			};

			await updateComapany(data);
		} else {
			await addCompany(values);
		}
	};

	const updateComapany = async (data: any) => {
		try {
			setLoading(true);
		const response =	await putApi('/company', data);
			if (permissionContext.companyId === editSelectedUser?.id) {
				window.location.reload();
			} else {
				fetchCompanyData();
				closeDrawerByAnimation();
			}
		} catch (err: any) {
			let message = 'Something went wrong in updating company';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};
	const addCompany = async (finalData: any) => {
		try {
			setLoading(true);
		const response =	await postApi('/company/create', finalData);
			fetchCompanyData();
			window.location.reload();
			let message =
				response?.data?.message || 'Company created successfully';
			toastText(message, 'success');
		} catch (err: any) {
			let message = 'Something went wrong when creating to company';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className={styles['side-drawer-body']}>
				<Form
					name="basic"
					className={styles['side-drawer-form']}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: 600 }}
					onFinish={handleSubmit}
					initialValues={editSelectedUser}
					autoComplete="off"
				>
					<p className={styles['form-container-head-warning']}>
						<b>
							{' '}
							<sup>*</sup>
						</b>{' '}
						Indicated mandatory fields
					</p>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Company Name'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'name'}
							rules={[
								{
									required: true,
									message: 'Company name required',
									validateTrigger: 'onChange',
									validator: (_, value) =>
										value && value.trim() !== ''
											? Promise.resolve()
											: Promise.reject(
													'Please enter value'
											  ),
								},
								// {
								// 	max: 30,
								// 	message:
								// 		'Company name length must be less than 30 characters',
								// 	validateTrigger: 'onChange',
								// },
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Company TPIN'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'tpin'}
							rules={[
								{
									required: true,
									message: 'Company tpin required',
									validateTrigger: 'onChange',
								},
								{
									// type: 'number',
									message:
										'Company tpin length must have 10 digits',
									pattern: /^\d{10}$/,
									validateTrigger: 'onChange',
								},
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'number'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
					</div>
					{/* <div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Currency'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							name={'currency'}
							rules={[
								{
									required: true,
									message: 'Currency required',
									validateTrigger: 'onChange',
								},
							]}
							wrapperCol={{ span: 24 }}
						>
							<Select
								placeholder="Select Currency"
								className={styles['side-drawer-form--select']}
								size="large"
								disabled={false}
							>
								{Object.entries(currencyData).map(([code, name]) => (
									<Select.Option value={code} key={code}>
										{`${name} (${code})`}
									</Select.Option>
								))}
							
							</Select>
						</Form.Item>
					</div> */}
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Company Email'}{' '}
							{/* <span className="required-color">*</span> */}
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'email'}
							rules={[
								{
									message: 'Please enter your company email',
									validateTrigger: 'onChange',
								},
								{
									type: 'email',
									message: 'Please enter valid email',
									validateTrigger: 'onChange',
								},
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'email'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Company Address'}{' '}
							{/* <span className="required-color">*</span> */}
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'address'}
							rules={[
								{
									message:
										'Please enter your company address',
									validateTrigger: 'onChange',
								},
								// {
								// 	max: 50,
								// 	message:
								// 		'Company address length must be less than 50 characters',
								// 	validateTrigger: 'onChange',
								// },
							]}
							wrapperCol={{ span: 24 }}
						>
							<textarea
								className={styles['side-drawer-form--textarea']}
							/>
						</Form.Item>
					</div>

					<div className={styles['side-drawer-form__buttons']}>
						<Form.Item>
							<Buttons buttons={buttons} />
						</Form.Item>
					</div>
				</Form>
			</div>
		</>
	);
};

export default AddCompanyBody;
