import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import SearchComponent from 'components/Global/SearchComponent';
import styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';

type Props = {
	searchValue: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => void;
	button: any;
	fetchPayPoint:any;
	isLoading:any;
};

const PayPointHeader = (props: Props) => {
	const {
		searchValue,
		handleSearch,
		handleSearchApi,
		button,
		fetchPayPoint,
		isLoading,
	} = props;

	const permissions = useContext(PermissionContext);

	return (
		<div className={styles['employee-header']}>
			<Space>
				<SearchComponent
					className={styles['employee-header-item']}
					placeHolder="Search pay point and location..."
					prefixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchValue}
					size="large"
				/>
			</Space>
			{permissions.allowedPermissions.includes('Add_Pay_Point') && (
				<Space>
					<div className={styles['employee-header__buttons']}>
						<Tooltip title="Refresh all the Pay Point">
							<SyncOutlined
								style={{
									fontSize: '20px',
									cursor: isLoading
										? 'not-allowed'
										: 'pointer',
									color: '#584495',
									transition: 'transform 0.3s',
									marginRight: '10px',
								}}
								onClick={!isLoading ? fetchPayPoint : undefined}
							/>
						</Tooltip>
						<Buttons buttons={button} />
					</div>
				</Space>
			)}
		</div>
	);
};

export default PayPointHeader;
