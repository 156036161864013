import {
	DeleteOutlined,
	DownCircleOutlined,
	EditOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Space, Table, Tag } from 'antd';
import { PermissionContext } from 'components/Global/AuthLayout';
import dayjs from 'dayjs';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import {
	ApprovedByAccounts,
	DeleteActionSvg,
	EditActionSvg,
	LoanProcessing,
	Paid,
	PendingForApproval,
	RejectedByAccounts,
} from 'utils/svgs';
import { formatNumber } from 'utils/utils';
import styles from './index.module.scss';

const { Column } = Table;

type Props = {
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
	updateLoanRequestStatus: (id: string, status: string) => void;
	showDeleteModal: () => void;
	setDeleteLoan: any;
	confirmLoanModal: () => void;
	setUpdateEffectiveDate: any;
	fetchRecords: any;
	handleEdit: any;
};
const LoanRequestTable = (props: Props) => {
	const permissionContext = useContext(PermissionContext);
	const isEdit =
		permissionContext.allowedPermissions.includes('Edit_Loan_Request');
	const isDelete = permissionContext.allowedPermissions.includes(
		'Delete_Loan_Request'
	);
	const {
		tableData,
		showDeleteModal,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		setDeleteLoan,
		isLoading,
		updateLoanRequestStatus,
		confirmLoanModal,
		handleEdit,
		setUpdateEffectiveDate,
	} = props;

	const handleMenuClick = (record: any, e: any) => {
		if (record.status === 'LOAN_CREDITED') {
			return;
		} else if (record.status === e.key) {
			return;
		} else if (e.key === 'LOAN_CREDITED') {
			setUpdateEffectiveDate(record);
			confirmLoanModal();
		} else {
			updateLoanRequestStatus(record.id, e.key);
		}
	};

	const { data } = useSelector((state: any) => state?.userProfile);
	const statusMenu = (record: any) => (
		<Menu onClick={(e: any) => handleMenuClick(record, e)}>
			<Menu.Item
				key="PENDING_FOR_APPROVAL"
				className={styles['menuItem']}
				disabled={isOptionDisabled(
					record.status,
					'PENDING_FOR_APPROVAL'
				)}
			>
				<span className={styles['menuIcon']}>
					<PendingForApproval />
				</span>
				Pending for approval
			</Menu.Item>
			<Menu.Item
				key="APPROVED_BY_ACCOUNTS"
				className={styles['menuItem']}
				disabled={isOptionDisabled(
					record.status,
					'APPROVED_BY_ACCOUNTS'
				)}
			>
				<span className={styles['menuIcon']}>
					<ApprovedByAccounts />
				</span>
				Approved by accounts
			</Menu.Item>
			<Menu.Item
				key="REJECTED_BY_ACCOUNTS"
				className={styles['menuItem']}
				disabled={isOptionDisabled(
					record.status,
					'REJECTED_BY_ACCOUNTS'
				)}
			>
				<span className={styles['menuIcon']}>
					<RejectedByAccounts />
				</span>
				Rejected by accounts
			</Menu.Item>
			<Menu.Item
				key="LOAN_PROCESSING"
				className={styles['menuItem']}
				disabled={isOptionDisabled(record.status, 'LOAN_PROCESSING')}
			>
				<span className={styles['menuIcon']}>
					<LoanProcessing />
				</span>
				Loan processing
			</Menu.Item>
			<Menu.Item
				key="LOAN_CREDITED"
				className={styles['menuItem']}
				disabled={isOptionDisabled(record.status, 'LOAN_CREDITED')}
			>
				<span className={styles['menuIcon']}>
					<Paid />
				</span>
				Loan credited
			</Menu.Item>
		</Menu>
	);
	const disabledOptionsByStatus: { [key: string]: string[] } = {
		REJECTED_BY_ACCOUNTS: [
			'APPROVED_BY_ACCOUNTS',
			'PENDING_FOR_APPROVAL',
			'LOAN_CREDITED',
			'LOAN_PROCESSING',
		],
		LOAN_CREDITED: [
			'APPROVED_BY_ACCOUNTS',
			'REJECTED_BY_ACCOUNTS',
			'PENDING_FOR_APPROVAL',
			'LOAN_PROCESSING',
		],
		APPROVED_BY_ACCOUNTS: [
			'PENDING_FOR_APPROVAL',
			'REJECTED_BY_ACCOUNTS',
			'PENDING_FOR_APPROVAL',
		],

		LOAN_PROCESSING: [
			'REJECTED_BY_ACCOUNTS',
			'APPROVED_BY_ACCOUNTS',
			'PENDING_FOR_APPROVAL',
		],

		PENDING_FOR_APPROVAL: [
			'APPROVED_BY_ACCOUNTS',
			'REJECTED_BY_ACCOUNTS',
			'LOAN_CREDITED',
			'LOAN_PROCESSING',
		],
	};
	const isOptionDisabled = (currentStatus: string, option: string) => {
		return disabledOptionsByStatus[currentStatus]?.includes(option);
	};

	const deleteDataHandler = (id: string) => {
		setDeleteLoan(id);
		showDeleteModal();
	};

	const editDataHandler = (loanObject: any) => {
		handleEdit(loanObject);
	};

	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={tableData}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Employee Name | Code"
					dataIndex="employeeName"
					key="employeeName"
					filterSearch={true}
					// sorter={true}
					className="bg-white"
					render={(text: string, record: any) => (
						<>
							<span>{text}</span> |{' '}
							<span style={{color:'#584495'}}>
								{record?.employeeCode}
							</span>
						</>
					)}
				/>
				<Column
					title="Requested By"
					dataIndex="requestedBy"
					key="requestedBy"
					// sorter={true}
					className="bg-white"
					// render={(record: any) => {
					// 	return <Link to="/time-logs">{record}</Link>;
					// }}
				/>
				<Column
					title="Requested Date"
					dataIndex="createdAt"
					key="createdAt"
					sorter={true}
					className="bg-white"
					render={(value) => {
						return dayjs(value).format('DD/MM/YYYY ');
					}}
				/>
				<Column
					title="Approval Date"
					dataIndex="approvalDate"
					key="approvalDate"
					sorter={true}
					className="bg-white"
					render={(value) => (
						<div>
							{value ? dayjs(value).format('DD/MM/YYYY ') : '-'}
						</div>
					)}
					// render={(value) =>
					// 	value ? dayjs(value).format('DD/MM/YYYY ') : '-'
					// }
				/>
				<Column
					title="Credited Date"
					dataIndex="effectiveStartDate"
					key="effectiveStartDate"
					sorter={true}
					className="bg-white"
					render={(value) => (
						<div>
							{value ? dayjs(value).format('DD/MM/YYYY ') : '-'}
						</div>
					)}
				/>
				{/* <Column
					title="Repayment Date"
					dataIndex="repaymentDate"
					key="repaymentDate"
					// sorter={true}
					className="bg-white"
					render={(value) => {
						return dayjs(value).format('DD/MM/YYYY hh:mm A');
					}}
				/> */}
				<Column
					title="Loan Amount"
					dataIndex="amount"
					key="amount"
					sorter={true}
					className="bg-white"
					render={(value: string, record: any) => {
						// Check if the current row's isUSD value is true
						if (record.isUSD) {
							return formatNumber(
								Number(value),
								data?.CompanyCurrencies,
								true // USD formatting
							);
						} else {
							return formatNumber(
								Number(value),
								data?.CompanyCurrencies // Local currency formatting
							);
						}
					}}
				/>
				<Column
					title="Status"
					dataIndex="status"
					key="status"
					className={`${styles['statusColumn']} bg-white`}
					// className={styles['statusColumn']}
					render={(value, record) => {
						let statusText = '';
						let statusClass = '';
						let StatusIcon = null;

						switch (value) {
							case 'PENDING_FOR_APPROVAL':
								statusText = 'Pending for approval';
								statusClass =
									styles['status-pending-for-approval'];
								StatusIcon = <PendingForApproval />;
								break;
							case 'APPROVED_BY_ACCOUNTS':
								statusText = 'Approved by accounts';
								statusClass =
									styles['status-approved-by-accounts'];
								StatusIcon = <ApprovedByAccounts />;
								break;
							case 'REJECTED_BY_ACCOUNTS':
								statusText = 'Rejected by accounts';
								statusClass =
									styles['status-rejected-by-accounts'];
								StatusIcon = <RejectedByAccounts />;
								break;
							case 'LOAN_PROCESSING':
								statusText = 'Loan processing';
								statusClass = styles['status-loan-processing'];
								StatusIcon = <LoanProcessing />;
								break;
							case 'LOAN_CREDITED':
								statusText = 'Loan credited';
								statusClass = styles['status-loan-credited'];
								StatusIcon = <Paid />;
								break;
							default:
								statusText = 'Unknown Status';
								statusClass = styles['status-unknown'];
								StatusIcon = null;
						}

						return (
							<div className={styles['statusContainer']}>
								<Dropdown
									overlay={statusMenu(record)}
									trigger={['click']}
								>
									<Button
										size="small"
										type="link"
										onClick={(e) => e.preventDefault()}
										className={styles['dropdownTrigger']}
									>
										<DownCircleOutlined />
									</Button>
								</Dropdown>

								<Tag
									bordered={false}
									className={`${styles['statusTag']} ${statusClass}`}
								>
									<span className={styles['statusIcon']}>
										{StatusIcon}
									</span>
									<span>{statusText}</span>
								</Tag>
							</div>
						);
					}}
				/>
				{/* {(isEdit || isDelete) && (
					<Column
						title="Action"
						key="action"
						render={(_: any, record: any) =>
							record.status !== 'PENDING_FOR_APPROVAL' ? (
								<></>
							) : (
								<Space size="middle">
									{isEdit && (
										<p className="cursor-pointer">
											<EditOutlined
												style={{ fontSize: 18 }}
												onClick={() =>
													editDataHandler(record)
												}
											/>
										</p>
									)}
									{isDelete && (
										<p className="cursor-pointer">
											<DeleteOutlined
												style={{
													fontSize: 18,
													cursor: 'pointer',
												}}
												// onClick={() => deleteDataHandler(record.id)}
												onClick={() =>
													deleteDataHandler(record.id)
												}
											/>
										</p>
									)}
								</Space>
							)
						}
					/>
				)} */}
				<Column
					title="Action"
					key="action"
					className="bg-white"
					render={(_: any, record: any) => (
						<Space size="middle">
							{isEdit &&
							record.status === 'PENDING_FOR_APPROVAL' ? (
								<p
									className="cursor-pointer flex align-center justify-center"
									style={{ fontSize: 18 }}
									onClick={() => editDataHandler(record)}
								>
									<EditOutlined />
								</p>
							) : (
								<p className="cursor-not-allowed flex align-center justify-center">
									<EditActionSvg color="#928F8F" />
								</p>
							)}
							{isDelete &&
							record.status === 'PENDING_FOR_APPROVAL' ? (
								<p
									className="cursor-pointer flex align-center justify-center"
									style={{
										fontSize: 18,
										cursor: 'pointer',
									}}
									onClick={() => deleteDataHandler(record.id)}
								>
									<DeleteActionSvg />
								</p>
							) : (
								<p className="cursor-not-allowed flex align-center justify-center">
									<DeleteActionSvg color="#928F8F" />
								</p>
							)}
						</Space>
					)}
				/>
			</Table>
		</div>
	);
};

export default LoanRequestTable;
