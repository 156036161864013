import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Col, Divider, Modal, Row, Tooltip } from 'antd';
import { postApi } from 'apis';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import ImageUpload from 'components/Global/imageUpload';
import InputField from 'components/Global/InputField';
import SelectDropdown from 'components/Global/SelectDropdown';
import { countryPhoneCodes } from 'constants/CurrencyData';
import { dateFormats, monthsForCompanySetup } from 'constants/Data';
import { countryStateData } from 'constants/StateCountryData';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
	decryptForStanbic,
	hasFormError,
	invalidText,
	toastText,
	validateFormData,
} from 'utils/utils';
import { fetchCompanyWizard } from '../../../../../../redux/actions/payrollSettingWizardAction';
import styles from './index.module.scss';

type Props = {
	changeTab: (key: string) => void;
	onCancel: () => void;
	editData: any;
};

export const CompanyDetails = (props: Props) => {
	const { changeTab, onCancel } = props;

	const [isLoading, setIsLoading] = useState(false);
	const [hasError, setHasError] = useState(false);
	const [isPopupVisible, setIsPopupVisible] = useState(false);
	const [statutoryModal, setIsStatutoryModal] = useState(false);

	const [stanbicDetails, setStanbicDetails] = useState({
		customerId: '',
		userId: '',
	});

	const [stanbicDetailsError, setStanbicDetailsError] = useState<any>({
		customerId: false,
		userId: false,
	});

	const [companyBaseDetails, setCompanyBaseDetails] = useState<any>({
		companyName: '',
		email: null,
		// email: '',
		financialYear: '',
		napsa: '',
		nihma: '',
		tpin: '',
		dateFormat: '',
	});

	const [companyLocation, setCompanyLocation] = useState<any>({
		address1: '',
		address2: '',
		city: '',
		state: '',
		country: '',
		postalCode: '',
	});

	const [companyContactDetail, setCompanyContactDetail] = useState<any>({
		name: null,
		title: null,
		phone: null,
	});

	const [formError, setFormError] = useState<any>({
		//	email: false,
		lastName: false,
		financialYear: false,
		napsa: false,
		nihma: false,
		tpin: false,
		dateFormat: false,
		address1: false,
		address2: false,
		city: false,
		state: false,
		country: false,
		postalCode: false,
	});

	const [countryOptions, setCountryOptions] = useState<any[]>([]);

	const [stateOptions, setStateOptions] = useState<any[]>([]);

	const context = useContext(PermissionContext);

	const dispatch = useDispatch<AppDispatch>();

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: isLoading,
			onclick: () => {
				setIsStatutoryModal(false);
				onCancel();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
		{
			text: 'Save & Next',
			isLoading: isLoading,
			className: 'btn-blue',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleSubmit();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
	];

	const extractStateOptions = (countryIso2: string) => {
		const country = countryStateData.data.find(
			(c) => c.iso2 === countryIso2
		);
		if (country) {
			return country.states.map((state) => ({
				label: state.name,
				value: state.state_code,
			}));
		}
		return [];
	};

	const handleCountryChange = (value: any) => {
		handleChange(value, 'country', 'companyLocation', true);
		handleChange(null, 'state', 'companyLocation', true);
		// Update phone number based on selected country
		const phoneCode = countryPhoneCodes[value];
		if (phoneCode) {
			handleChange(phoneCode, 'phone', 'companyContactDetail', false);
		}
	};

	const handleChangeForStanbicDetails = (
		value: string | number | null | string[],
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required && typeof value === 'string') {
			setStanbicDetailsError((prev: any) => {
				return {
					...prev,
					[name]: invalidText(value),
				};
			});
		}

		setStanbicDetails((prev: any) => {
			return {
				...prev,
				[name]: value,
			};
		});
	};

	const handleChange = (
		value: string | number | null | string[],
		name: string,
		stateName: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}
		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		} else {
			setHasError(false);
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}

		OnChange(value, name, stateName);
	};

	const OnChange = (
		value: string | number | null | string[],
		key: string,
		stateName: string
	) => {
		switch (stateName) {
			case 'companyBaseDetails':
				setCompanyBaseDetails((prev: any) => {
					return {
						...prev,
						[key]: value,
					};
				});
				break;
			case 'companyLocation':
				setCompanyLocation((prev: any) => {
					return {
						...prev,
						[key]: value,
					};
				});
				break;
			case 'companyContactDetail':
				setCompanyContactDetail((prev: any) => {
					return {
						...prev,
						[key]: value,
					};
				});
				break;
		}
		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);

		setFormError(checkFormError);
	};

	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{
				...companyBaseDetails,
				...companyLocation,
				...companyContactDetail,
			},
			{ ...formError }
		);

		let stanbicDetailsErrors = validateFormData(
			{
				...stanbicDetails,
			},
			{ ...stanbicDetailsError }
		);
		stanbicDetailsErrors = { ...stanbicDetailsErrors };

		setStanbicDetailsError(stanbicDetailsErrors);

		checkFormError = { ...checkFormError };

		setFormError(checkFormError);

		if (
			hasFormError(checkFormError) ||
			hasFormError(stanbicDetailsErrors)
		) {
			return;
		} else {
			if (!statutoryModal) {
				setIsPopupVisible(true);
			} else {
				handleConfirmSubmit();
			}
		}
	};

	const handleConfirmSubmit = async () => {
		setIsPopupVisible(false);

		try {
			const companyDetails = {
				financialYear: parseInt(companyBaseDetails.financialYear, 10),
				napsa: String(companyBaseDetails.napsa),
				nihma: String(companyBaseDetails.nihma),
				dateFormat: companyBaseDetails.dateFormat,
				address1: companyLocation.address1,
				address2: companyLocation.address2,
				city: companyLocation.city,
				state: companyLocation.state,
				country: companyLocation.country,
				postalCode: parseInt(companyLocation.postalCode),
			};

			// Conditionally prepare contactDetails object
			const contactDetails =
				companyContactDetail.name ||
				companyContactDetail.title ||
				companyContactDetail.phone
					? {
							id: props?.editData?.ContactDetails[0]?.id,
							name: companyContactDetail.name || '',
							title: companyContactDetail.title || '',
							phone: companyContactDetail.phone || '',
					  }
					: {
							id: props?.editData?.ContactDetails[0]?.id,
							name: '',
							title: '',
							phone: '',
					  };

			// Prepare final data object
			const data: any = {
				companyDetails,
				...(contactDetails && { contactDetails }),
				stanbicDetails,
			};

			setIsLoading(true);
			const response = await postApi('/company/company-details', data);

			toastText('Company details updated successfully', 'success');
			dispatch(fetchCompanyWizard());
			props.changeTab('bankDetails');
		} catch (error: any) {
			const message =
				error?.response?.data?.message ||
				'Something went wrong in fetching employee activity.';
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	const handleCancelSubmit = () => {
		setIsPopupVisible(false);
	};

	useEffect(() => {
		setCountryOptions(
			countryStateData.data.map((country) => ({
				label: country.name,
				value: country.iso2,
			}))
		);
	}, []);

	useEffect(() => {
		if (companyLocation.country) {
			const states = extractStateOptions(companyLocation.country);
			setStateOptions(states);
		} else {
			setStateOptions([]);
		}
	}, [companyLocation.country]);

	useEffect(() => {
		if (props.editData) {
			setCompanyBaseDetails({
				companyName: props.editData.name,
				email: props?.editData?.email,
				tpin: props.editData.tpin,
				nihma: props?.editData?.CompanyDetails?.nihma,
				napsa: props?.editData?.CompanyDetails?.napsa,
				financialYear: props?.editData?.CompanyDetails?.financialYear,
				dateFormat: props?.editData?.CompanyDetails?.dateFormat,
			});

			props?.editData?.CompanyDetails?.napsa
				? setIsStatutoryModal(true)
				: setIsStatutoryModal(false);
			setCompanyLocation({
				address1: props?.editData?.CompanyDetails?.address1,
				address2: props?.editData?.CompanyDetails?.address2,
				city: props?.editData?.CompanyDetails?.city,
				state: props?.editData?.CompanyDetails?.state,
				country: props?.editData?.CompanyDetails?.country,
				postalCode: props?.editData?.CompanyDetails?.postalCode,
			});
			setCompanyContactDetail({
				id: props?.editData?.ContactDetails[0]?.id,
				name: props?.editData?.ContactDetails[0]?.name,
				phone: props?.editData?.ContactDetails[0]?.phone,
				title: props?.editData?.ContactDetails[0]?.title,
			});

			if (props?.editData?.StanbicDetails) {
				let decryptedCustomerId = decryptForStanbic(
					props?.editData?.StanbicDetails?.stanbicCustomerId
				);
				let decryptedUserId = decryptForStanbic(
					props?.editData?.StanbicDetails?.stanbicUserId
				);

				setStanbicDetails({
					customerId: decryptedCustomerId,
					userId: decryptedUserId,
				});
			}
		}
	}, [props.editData]);

	return (
		// <>
		<div>
			<p className={styles['form-container-head-warning']}>
				<b>
					{' '}
					<sup>*</sup>
				</b>{' '}
				Indicated mandatory fields
			</p>
			<div className={styles['scrollable-content']}>
				<Row className={styles['form-container-card']} gutter={20}>
					<Col span={18} className={`${styles['col']}`}>
						<ImageUpload />
					</Col>
					<Col span={12} className={`${styles['col']} margin-top-10`}>
						<InputField
							name="companyName"
							value={companyBaseDetails.companyName}
							label="Company Name"
							required={true}
							helperText="Company name required"
							onChange={(value: any) => {
								handleChange(
									value,
									'companyName',
									'companyBaseDetails',
									true
								);
							}}
							isError={formError.companyName}
							disabled={true}
						/>
					</Col>
					<Col span={6} className={`${styles['col']} margin-top-10`}>
						<InputField
							name="email"
							value={companyBaseDetails.email}
							label="Email"
							required={false}
							helperText="Email required"
							onChange={(value: any) => {
								handleChange(
									value,
									'email',
									'companyBaseDetails',
									false
								);
							}}
							isError={formError.email}
							disabled={true}
						/>
					</Col>
					<Col span={6} className={`${styles['col']} margin-top-10`}>
						<SelectDropdown
							placeholder="Financial Year Start"
							options={monthsForCompanySetup}
							value={companyBaseDetails.financialYear}
							onChange={(value: any) => {
								handleChange(
									value,
									'financialYear',
									'companyBaseDetails',
									true
								);
							}}
							size="large"
							required={true}
							helperText="Financial Year is required"
							label="Financial Year Start"
							isError={formError.financialYear}
						/>
					</Col>
					<Col span={6} className={`${styles['col']} margin-top-10`}>
						<Tooltip title="NAPSA stands for the National Pension Scheme Authority, which is a mandatory savings plan for employees.">
							<div>
								<InputField
									name="napsa"
									value={companyBaseDetails.napsa}
									label="NAPSA"
									required={true}
									helperText="Napsa required"
									onChange={(value: any) => {
										handleChange(
											value,
											'napsa',
											'companyBaseDetails',
											true
										);
									}}
									isError={formError.napsa}
									disabled={
										props?.editData?.CompanyDetails?.napsa
									}
								/>
							</div>
						</Tooltip>
					</Col>
					<Col span={6} className={`${styles['col']} margin-top-10`}>
						<Tooltip title="NHIMA stands for the National Health Insurance Management Authority, which is a mandatory savings plan for employees.">
							<div>
								<InputField
									name="nihma"
									value={companyBaseDetails.nihma}
									label="NHIMA"
									required={true}
									helperText="Nihma required"
									onChange={(value: any) => {
										handleChange(
											value,
											'nihma',
											'companyBaseDetails',
											true
										);
									}}
									isError={formError.nihma}
									disabled={
										props?.editData?.CompanyDetails?.nihma
									}
								/>
							</div>
						</Tooltip>
					</Col>
					<Col span={6} className={`${styles['col']} margin-top-10`}>
						<InputField
							name="tpin"
							value={companyBaseDetails.tpin}
							label="TPIN"
							required={true}
							helperText="Tpin required"
							onChange={(value: any) => {
								handleChange(
									value,
									'tpin',
									'companyBaseDetails',
									true
								);
							}}
							disabled={true}
							isError={formError.tpin}
						/>
					</Col>
					<Col span={6} className={`${styles['col']} margin-top-10`}>
						<SelectDropdown
							placeholder="Date Format"
							options={dateFormats}
							value={companyBaseDetails.dateFormat}
							onChange={(value: any) => {
								handleChange(
									value,
									'dateFormat',
									'companyBaseDetails',
									true
								);
							}}
							size="large"
							required={true}
							helperText="Date Format is required"
							label="Date Format"
							isError={formError.dateFormat}
						/>
					</Col>

					<Divider className={styles['dividerStyle']} />

					<Col span={24} className={`${styles['col']}`}>
						<div
							style={{
								fontSize: '1.8rem',
								fontWeight: '500',
								color: '#584495',
							}}
						>
							Company Location
						</div>
					</Col>
					<Col span={12} className={`${styles['col']} margin-top-10`}>
						<InputField
							name="address1"
							value={companyLocation.address1}
							label="Address 1"
							required={true}
							helperText="address1 required"
							onChange={(value: any) => {
								handleChange(
									value,
									'address1',
									'companyLocation',
									true
								);
							}}
							isError={formError.address1}
						/>
					</Col>
					<Col span={12} className={`${styles['col']} margin-top-10`}>
						<InputField
							name="address2"
							value={companyLocation.address2}
							label="Address 2"
							required={true}
							helperText="address2 required"
							onChange={(value: any) => {
								handleChange(
									value,
									'address2',
									'companyLocation',
									true
								);
							}}
							isError={formError.address2}
						/>
					</Col>
					<Col span={6} className={`${styles['col']} margin-top-10`}>
						<SelectDropdown
							placeholder="Country"
							options={countryOptions}
							value={companyLocation.country}
							onChange={handleCountryChange}
							// onChange={(value: any) => {
							// 	handleChange(
							// 		value,
							// 		'country',
							// 		'companyLocation',
							// 		true
							// 	);
							// }}
							size="large"
							required={true}
							helperText="Country is required"
							label="Country"
							isError={formError.country}
						/>
					</Col>
					<Col span={6} className={`${styles['col']} margin-top-10`}>
						<SelectDropdown
							placeholder=" State "
							options={stateOptions}
							value={companyLocation.state}
							onChange={(value: any) => {
								handleChange(
									value,
									'state',
									'companyLocation',
									true
								);
							}}
							size="large"
							required={true}
							helperText="State is required"
							label="State"
							isError={formError.state}
						/>
					</Col>
					<Col span={6} className={`${styles['col']} margin-top-10`}>
						<InputField
							name="city"
							value={companyLocation.city}
							label="City"
							required={true}
							helperText="city required"
							onChange={(value: any) => {
								handleChange(
									value,
									'city',
									'companyLocation',
									true
								);
							}}
							isError={formError.city}
						/>
					</Col>
					<Col span={6} className={`${styles['col']} margin-top-10`}>
						<InputField
							name="postalCode"
							value={companyLocation.postalCode}
							label="Postal Code"
							required={true}
							helperText="postal code required"
							onChange={(value: any) => {
								handleChange(
									value,
									'postalCode',
									'companyLocation',
									true
								);
							}}
							isError={formError.postalCode}
						/>
					</Col>

					<Divider className={styles['dividerStyle']} />

					<Col span={24} className={`${styles['col']}`}>
						<div
							style={{
								fontSize: '1.8rem',
								fontWeight: '500',
								color: '#584495',
							}}
						>
							Company's Contact Person Details
						</div>
					</Col>
					<Col span={12} className={`${styles['col']} margin-top-10`}>
						<InputField
							name="name"
							value={companyContactDetail.name}
							label="Contact Name"
							required={false}
							// helperText="Contact name required"
							onChange={(value: any) => {
								handleChange(
									value,
									'name',
									'companyContactDetail',
									true
								);
							}}
							isError={formError.name}
						/>
					</Col>
					<Col span={6} className={`${styles['col']} margin-top-10`}>
						<InputField
							name="title"
							value={companyContactDetail.title}
							label="Title"
							required={false}
							//	helperText="Contact name required"
							onChange={(value: any) => {
								handleChange(
									value,
									'title',
									'companyContactDetail',
									true
								);
							}}
							isError={formError.title}
						/>
					</Col>

					<Col span={6} className={`${styles['col']} margin-top-10`}>
						<InputField
							name="phone"
							value={companyContactDetail.phone}
							label="PhoneNumber"
							required={false}
							onChange={(value: any) => {
								handleChange(
									value,
									'phone',
									'companyContactDetail',
									true
								);
							}}
							isError={formError.phone}
						/>
					</Col>

					{context.isAdmin && (
						<>
							<Divider className={styles['dividerStyle']} />

							<Col
								span={24}
								className={`${styles['col']} margin-top-10`}
							>
								<div
									style={{
										fontSize: '1.8rem',
										fontWeight: '500',
										color: '#584495',
									}}
								>
									Stanbic Details
								</div>
							</Col>
							<Col
								span={12}
								className={`${styles['col']} margin-top-10`}
							>
								<InputField
									name="Customer Id"
									value={stanbicDetails.customerId}
									label="Customer Id"
									required={true}
									helperText="Enter Valid customer Id"
									onChange={(value: any) => {
										handleChangeForStanbicDetails(
											value,
											'customerId',
											true
										);
									}}
									isError={stanbicDetailsError.customerId}
								/>
							</Col>
							<Col
								span={12}
								className={`${styles['col']} margin-top-10`}
							>
								<InputField
									name="User Id"
									value={stanbicDetails.userId}
									label="User Id"
									required={true}
									helperText="Enter Valid user Id"
									onChange={(value: any) => {
										handleChangeForStanbicDetails(
											value,
											'userId',
											true
										);
									}}
									isError={stanbicDetailsError.userId}
								/>
							</Col>
						</>
					)}
				</Row>
			</div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'start',
					marginTop: '20px',
				}}
			>
				<Buttons buttons={buttons} />
			</div>
			<Modal
				title={
					<>
						<ExclamationCircleOutlined
							style={{ color: 'red', marginRight: 8 }}
						/>
						Confirm Submission
					</>
				}
				open={isPopupVisible}
				closable={false}
				onOk={handleConfirmSubmit}
				onCancel={handleCancelSubmit}
				okText="Confirm"
				cancelText="Cancel"
			>
				<p>
					Once saved, the <strong>Company Name</strong>,{' '}
					<strong>Email</strong>, <strong>NAPSA</strong>,{' '}
					<strong>NHIMA</strong>, and <strong>TPIN </strong>
					cannot be modified. Are you sure you want to proceed?
				</p>
			</Modal>
		</div>
	);
};
