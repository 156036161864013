import { DollarCircleOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import { Alert, Card, Radio, Typography } from 'antd';
import { directDepositApi } from 'Api/directDeposit';
import CustomCollapsePanel from 'components/Global/Collpase';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { RightArrowSvg } from 'utils/svgs';
import { decrypt, toastText } from 'utils/utils';
import DirectDeposit from './DirectDeposit';
import styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';

const { Title, Paragraph } = Typography;

type Props = {
	isUsd: boolean;
};

const PaymentInfo = (props: Props) => {
	const { isUsd } = props;
	const [activeKey, setActiveKey] = useState<string | null>(null);
	const [isVerifiedAccount, setIsVerifiedAccount] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState<
		'direct-deposit' | 'cash'
	>('direct-deposit');

	const [isStanbic, setIsStanbic] = useState(false);
	const [isCashSaved, setIsCashSaved] = useState(false);
	const [searchParams] = useSearchParams();
	const [paymentDetails, setPaymentDetails] = useState<any>({
		accountHolderName: null,
		bankType: null,
		accountNumber: null,
		sortCode: null,
		isVerified: false,
		beneficiaryAddr1: null,
		beneficiaryAddr2: null,
		beneficiaryAddr3: null,
		beneficiaryBankCode: null,
	});

	const myButtons = [
		{
			text: 'Save Cash',
			isLoading: isCashSaved,
			className: 'primary-button',
			fontSize: '1.4rem',
			minWidth: '90px',
			minHeight: '33px',
			isSubmit: true,
			onclick: () => handleCashSubmit(),
			disabled: false,
		},
	];

	const [apiCurrencyCode, setApiCurrencyCode] = useState<any>(null);

	const [isLoading, setIsLoading] = useState(false);

	const handleCollapseClose = () => {
		setActiveKey(null); // Close all panels
	};

	const handleCashSubmit = async () => {
		try {
			setIsCashSaved(true);
			await directDepositApi.saveCashPayment({
				employeeId: searchParams.get('employeeId'),
			});
			toastText('Cash payment option saved successfully', 'success');
		} catch (error) {
		} finally {
			setIsCashSaved(false);
		}
	};

	const trueVerifiedAccount = () => {
		setIsVerifiedAccount(true);
	};

	const falseVerifiedAccount = () => {
		setIsVerifiedAccount(false);
	};

	const fetchDirectDepositDetails = async () => {
		setIsLoading(true);
		const employeeId = searchParams.get('employeeId');

		if (!employeeId) {
			toastText('Employee ID is missing', 'error');
			setIsLoading(false);
			return;
		}
		try {
			const response =
				await directDepositApi.fetchDirectDepositByEmployeeId(
					employeeId
				);
			const data = response?.data;

			let decryptedAccountNumber;
			if (data.accountNumber) {
				try {
					decryptedAccountNumber = decrypt(data.accountNumber);
				} catch (error) {
					decryptedAccountNumber = data.accountNumber || '';
				}
			}

			setPaymentDetails({
				accountHolderName: data.accountHolderName,
				bankType: data.bankType,
				accountNumber: decryptedAccountNumber,
				sortCode: data.sortCode,
				isVerified: data.isVerified,
				beneficiaryAddr1: data?.beneficiaryAddr1,
				beneficiaryAddr2: data?.beneficiaryAddr2,
				beneficiaryAddr3: data?.beneficiaryAddr3,
				beneficiaryBankCode: data.beneficiaryBankCode,
			});
			data.isVerified ? trueVerifiedAccount() : falseVerifiedAccount();

			if (data.bankType === 'FIRST_CAPITAL_BANK') {
				setIsStanbic(false);
			} else {
				setIsStanbic(true);
			}

			if (data.isVerified) {
				isUsd ? setApiCurrencyCode('USD') : setApiCurrencyCode('ZMW');
			}
		} catch (err) {
			// toastText('Failed to load direct deposit details', 'error');
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		fetchDirectDepositDetails();
	}, [searchParams]);

	const renderCashPaymentOption = () => (
		<Card>
			<div style={{ textAlign: 'center' }}>
				<MoneyCollectOutlined
					style={{ fontSize: '64px', color: '#52c41a' }}
				/>
				<Title
					level={4}
					style={{
						color: '#584495',
					}}
				>
					Cash Payment
				</Title>
				<Paragraph type="secondary">
					Manually pay the employee in cash
				</Paragraph>
				<Alert
					message="Cash Payment Guidelines"
					description={
						<ul style={{ textAlign: 'left' }}>
							<li>Count the cash carefully</li>
							<li>Obtain a signed receipt</li>
							<li>Keep proper documentation</li>
						</ul>
					}
					type="warning"
					showIcon
				/>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '12px',
					}}
				>
					<Buttons buttons={myButtons} />
				</div>
			</div>
		</Card>
	);

	return (
		<div className={styles['form']}>
			<div className={styles['form-container']}>
				<p className={styles['form-container-title']}>
					How would you like to pay this employee?{' '}
					<sup className={styles['collapse-required']}>*</sup>
				</p>

				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginBottom: '16px',
					}}
				>
					<Radio.Group
						value={paymentMethod}
						onChange={(e) => setPaymentMethod(e.target.value)}
						optionType="button"
						buttonStyle="solid"
					>
						<Radio.Button value="direct-deposit">
							<DollarCircleOutlined /> Direct Deposit
						</Radio.Button>
						<Radio.Button value="cash">
							<MoneyCollectOutlined /> Cash
						</Radio.Button>
					</Radio.Group>
				</div>

				<div className={styles.collapse}>
					{paymentMethod === 'direct-deposit' && (
						<div className={styles['collapse-menu']}>
							<CustomCollapsePanel
								key={isLoading ? 'loading' : 'loaded'}
								header={
									isLoading
										? 'Direct Deposit(Loading...)'
										: isVerifiedAccount
										? 'Direct Deposit (Verified)'
										: 'Direct Deposit (Not Verified)'
								}
								icon={<RightArrowSvg />}
								activeKey={activeKey}
								setActiveKey={setActiveKey}
								panelKey="0"
								isOpen={false}
							>
								<DirectDeposit
									onCancel={handleCollapseClose}
									falseVerifiedAccount={falseVerifiedAccount}
									trueVerifiedAccount={trueVerifiedAccount}
									isDetailsLoading={isLoading}
									paymentDetails={paymentDetails}
									isUsd={isUsd}
									apiCurrencyCode={apiCurrencyCode}
									setApiCurrencyCode={setApiCurrencyCode}
									isStanbic={isStanbic}
									setIsStanbic={setIsStanbic}
								/>
							</CustomCollapsePanel>
						</div>
					)}

					{paymentMethod === 'cash' && renderCashPaymentOption()}
				</div>
			</div>
		</div>
	);
};

export default PaymentInfo;
