import { companySetup } from 'Api/companySetup';
import { payPeriodApi } from 'Api/payPeriod';
import { Button, Col, Form, Modal, Radio, Row, Table, Typography } from 'antd';
import Column from 'antd/es/table/Column';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import InputField from 'components/Global/InputField';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
	formatToDateOnly,
	formatWorkWeekForEdit,
	generatePayPeriodsForFinancialYear,
	getFinancialYearRange,
	hasFormError,
	invalidText,
	payPeriodNameFn,
	toastText,
	validateFormData,
} from 'utils/utils';
import { fetchCompanyWizard } from '../../../../../redux/actions/payrollSettingWizardAction';
import styles from './index.module.scss';
import './index.scss';
import { SideDrawerBodyProps } from './types';

const AddPayPeriodSideDrawer: FC<SideDrawerBodyProps> = (props) => {
	const {
		closeDrawerByAnimation,
		fetchAllPayPeriodDetails,
		editData,
		isEdit,
	} = props;
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedOption, setSelectedOption] =
		useState<string>('payOnLastDay');
	const [payPeriodData, setPayPeriodData] = useState<any>({
		year: null,
		payrollStartDate: null,
		FinancialYearRange: null,
		payrollEndDate: null,
		employeePayDate: null,
		payPeriodName: '',
	});
	const [confirmModal, setConfirmModal] = useState<boolean>(false);
	const [formError, setFormError] = useState<any>({
		year: false,
		payrollStartDate: false,
		payrollEndDate: false,
		employeePayDate: false,
		payPeriodName: false,
	});

	const [hasError, setHasError] = useState(false);
	const [selectedDays, setSelectedDays] = useState<string[]>([]);
	const [financialMonth, setFinancialMonth] = useState<number | null>(null);
	const [financialMonthLoading, setFinancialMonthLoading] =
		useState<boolean>(false);

	const [globalData, setGlobalData] = useState<any[]>([]);
	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			className: 'btn-cancel',
			isSubmit: true,
			disabled: false,
			onclick: () => {
				closeDrawerByAnimation();
			},
			font: '16px',
		},
		{
			text: 'Save',
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			disabled: false,
			onclick: () => {
				handleSubmit();
			},
		},
	];

	const ModalButtons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: loading,
			onclick: () => {
				setConfirmModal(false);
			},
		},
		{
			text: 'Confirm',
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {
				handleConfirm();
			},
		},
		
	];

	const dispatch = useDispatch<AppDispatch>();
	// const formatPayPeriodName = (payPeriodData: { payrollStartDate: string; payrollEndDate: string; payPeriodName: string }): string => {
	// 	const startOfMonth = dayjs(payPeriodData.payrollStartDate).format('DD/MM/YYYY');
	// 	const endOfMonth = dayjs(payPeriodData.payrollEndDate).format('DD/MM/YYYY');
	// 	return `${payPeriodData.payPeriodName} (${startOfMonth} - ${endOfMonth})`;
	// };

	// const handlePayPeriodNameBlur = (value: string) => {
	// 	const formattedValue = formatPayPeriodName(payPeriodData);
	// 	setPayPeriodData((prev: any) => ({
	// 		...prev,
	// 		payPeriodName: formattedValue,
	// 	}));
	// 	OnChange(formattedValue, 'payPeriodName');
	// 	// handleChange(formattedValue, 'payPeriodName', true);
	// };

	const handleChange = (
		value: string | number | null,
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required) {
			setHasError(invalidText(value));
		}

		if (typeof value === 'string' && regex) {
			const _regex = new RegExp(regex);
			setHasError(!_regex.test(value));
		}

		if (name === 'year') {
			if (isEdit) {
				return;
			}
			const fiscalMonth = financialMonth;
			const startDateOfFiscalMonth = dayjs()
				.year(dayjs(value).year())
				.month(fiscalMonth ?? 0)
				.startOf('month');

			const _FinancialYearRange = getFinancialYearRange(
				dayjs(value).year(),
				fiscalMonth ?? 0
			);
			const payPeriodName = payPeriodNameFn(
				startDateOfFiscalMonth as unknown as string,
				dayjs(startDateOfFiscalMonth).add(
					29,
					'days'
				) as unknown as string
			);
			setPayPeriodData((prev: any) => {
				const newDetails = {
					...prev,
					year: dayjs(value).format('YYYY'),
					payrollStartDate: startDateOfFiscalMonth,
					FinancialYearRange: _FinancialYearRange,
					employeePayDate: dayjs(startDateOfFiscalMonth)
						.add(30, 'days')
						.endOf('month'),
					payrollEndDate: dayjs(startDateOfFiscalMonth).add(
						29,
						'days'
					),
					payPeriodName: payPeriodName,
				};
				return newDetails;
			});
		}

		if (name === 'payrollStartDate') {
			setPayPeriodData((prev: any) => {
				const newDetails = {
					...prev,
					payrollEndDate: null,
					payPeriodName: null,
					employeePayDate: null,
				};
				return newDetails;
			});
		}
		if (name === 'payrollEndDate') {
			const payPeriodName = payPeriodNameFn(
				payPeriodData.payrollStartDate,
				value as string
			);
			setPayPeriodData((prev: any) => {
				const newDetails = {
					...prev,
					payPeriodName,
					//last day of the dominant month
					employeePayDate: dayjs(value).endOf('month'),
				};
				return newDetails;
			});
		}

		OnChange(value, name);
	};
	const OnChange = (value: string | number | null, key: string) => {
		setPayPeriodData((prev: any) => {
			const newDetails = {
				...prev,
				[key]: value,
			};
			return newDetails;
		});
		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);
		setFormError(checkFormError);
	};

	const handleConfirm = async () => {
		setLoading(true);
		const _payPeriodData = globalData.map((data) => {
			return {
				payrollStartDate: data.payrollStartDate,
				payrollEndDate: data.payrollEndDate,
			};
		});
		try {
			if (isEdit) {
				await payPeriodApi.updatePayPeriod({
					payPeriodData: _payPeriodData,
					year: dayjs(payPeriodData.year).format('YYYY'),
					workWeek: globalData[0].workWeek.split(','),
					id: editData.id,
				});
			} else {
				await payPeriodApi.createPayPeriod({
					payPeriodData: _payPeriodData,
					year: dayjs(payPeriodData.year).format('YYYY'),
					workWeek: globalData[0].workWeek.split(','),
				});
				toastText('Pay Period details saved successfully', 'success');
			}

			setConfirmModal(false);
			fetchAllPayPeriodDetails();
			dispatch(fetchCompanyWizard());
			closeDrawerByAnimation();
		} catch (error: any) {
			toastText(error.response?.data.message, 'error');
		} finally {
			setLoading(false);
		}
	};

	const [form] = Form.useForm();

	const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

	const handleDaySelect = (day: string) => {
		setSelectedDays((prev) =>
			prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
		);
		form.validateFields(['workWeek']);
	};
	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{ ...payPeriodData },
			{ ...formError }
		);
		setFormError(checkFormError);

		if (hasFormError(checkFormError)) {
			return;
		}
		const tableConfirmData = {
			year: dayjs(payPeriodData.year).format('YYYY'),
			payrollStartDate: formatToDateOnly(payPeriodData.payrollStartDate),
			FinancialYearRange: payPeriodData.FinancialYearRange,
			payrollEndDate: formatToDateOnly(payPeriodData.payrollEndDate),
			financialMonth: financialMonth,
			workWeek: selectedDays,
			payPeriodName: payPeriodData.payPeriodName,
		};

		const allPayPeriodData = generatePayPeriodsForFinancialYear(
			tableConfirmData,
			isEdit
		);
		setGlobalData(allPayPeriodData);
		setConfirmModal(true);
	};

	const fetchFinancialYear = async () => {
		try {
			setFinancialMonthLoading(true);
			const financialYear = await companySetup.getFinancialYear();
			const _month = financialYear.data.data;
			setFinancialMonth(_month - 1);
		} catch (error: any) {
			toastText(error.response?.data?.message, 'error');
		} finally {
			setFinancialMonthLoading(false);
		}
	};

	useEffect(() => {
		fetchFinancialYear();
	}, []);

	useEffect(() => {
		if (isEdit) {
			const dateRange = getFinancialYearRange(
				editData.year,
				financialMonth ?? 0
			);
			setPayPeriodData({
				year: dayjs().year(editData.year),
				payrollStartDate: dayjs(editData.startDate),
				payrollEndDate: dayjs(editData.endDate),
				payPeriodName: editData.name,
				FinancialYearRange: dateRange,
				employeePayDate: dayjs(editData.endDate).endOf('month'),
			});
		}
	}, [isEdit, financialMonth, editData]);

	useEffect(() => {
		if (isEdit) {
			setSelectedDays(
				formatWorkWeekForEdit(editData.workWeek).split(',')
			);
		} else {
			setSelectedDays(['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']);
		}
	}, [isEdit, editData]);

	return (
		<>
			<div className={styles['side-drawer-body']}>
				<Form
					name="basic"
					className={styles['side-drawer-form']}
					form={form}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 24 }}
					style={{ maxWidth: 600 }}
					autoComplete="off"
				>
					<p className={styles['form-container-head-warning']}>
						<b>
							{' '}
							<sup>*</sup>
						</b>{' '}
						Indicated mandatory fields
					</p>
					<div className={styles['side-drawer-form__inputs']}>
						<Row gutter={24}>
							<Col span={12}>
								<div className={styles['field-container']}>
									<div
										className={
											styles['field-container-label']
										}
									>
										<label
											className={
												styles[
													'side-drawer-form__napsa--label'
												]
											}
										>
											{'Select a year'}{' '}
											<span className="required-color">
												*
											</span>
										</label>
									</div>
									<DatePickerField
										onChange={(value) => {
											handleChange(value, 'year', true);
										}}
										value={payPeriodData.year}
										isError={formError.year}
										disabledBeforeDates={dayjs().startOf(
											'day'
										)}
										placeholder="Select a year"
										required
										name="year"
										helperText="Select the year"
										picker="year"
										disabled={
											financialMonthLoading || isEdit
										}
									/>
								</div>
							</Col>
							<Col span={12}>
								<div className={styles['field-container']}>
									<div
										className={
											styles['field-container-label']
										}
									>
										<label
											className={
												styles[
													'side-drawer-form__napsa--label'
												]
											}
										>
											{'Financial Year'}{' '}
										</label>
									</div>
									<InputField
										name="financialYear"
										value={
											payPeriodData.year
												? payPeriodData.FinancialYearRange
												: 'Loading...'
										}
										label=""
										required={false}
										helperText="Pay period name required"
										onChange={(value) => {}}
										placeholder="Select a financial year"
										isError={false}
										disabled={true}
									/>
								</div>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={12}>
								<div className={styles['field-container']}>
									<div
										className={
											styles['field-container-label']
										}
									>
										<label
											className={
												styles[
													'side-drawer-form__napsa--label'
												]
											}
										>
											{'Start Date'}{' '}
											<span className="required-color">
												*
											</span>
										</label>
									</div>
									<DatePickerField
										onChange={(value) => {
											handleChange(
												value,
												'payrollStartDate',
												true
											);
										}}
										value={payPeriodData.payrollStartDate}
										isError={formError.payrollStartDate}
										disabled={
											!payPeriodData.year ||
											financialMonthLoading
										}
										placeholder="Select a payroll Start Date"
										required
										name="payrollStartDate"
										helperText="Select the start date of pay period"
										disabledBeforeDates={dayjs(
											`${dayjs(
												payPeriodData.year
											).year()}-${
												financialMonth
													? financialMonth + 1
													: 1
											}`
										).startOf('day')}
										disabledAfterDates={
											isEdit
												? dayjs(
														editData.startDate
												  ).endOf('month')
												: dayjs(
														`${dayjs(
															payPeriodData.year
														).year()}-${
															financialMonth
																? financialMonth +
																  1
																: 1
														}`
												  ).endOf('month')
										}
									/>
								</div>
							</Col>
							<Col span={12}>
								<div className={styles['field-container']}>
									<div
										className={
											styles['field-container-label']
										}
									>
										<label
											className={
												styles[
													'side-drawer-form__napsa--label'
												]
											}
										>
											{'End Date'}{' '}
											<span className="required-color">
												*
											</span>
										</label>
									</div>
									<DatePickerField
										onChange={(value) => {
											handleChange(
												value,
												'payrollEndDate',
												true
											);
										}}
										value={payPeriodData.payrollEndDate}
										isError={formError.payrollEndDate}
										disabled={
											!payPeriodData.year ||
											!payPeriodData.payrollStartDate
										}
										placeholder="Select a payroll End Date"
										required
										name="payrollEndDate"
										helperText="Select the end date of pay period"
										disabledAfterDates={
											dayjs(
												payPeriodData.payrollStartDate
											)
												.startOf('day')
												.add(30, 'day') ||
											dayjs(
												`${dayjs(
													payPeriodData.year
												).year()}-12`
											).endOf('day')
										}
										disabledBeforeDates={dayjs(
											payPeriodData.payrollStartDate
										).startOf('day')}
									/>
								</div>
							</Col>
						</Row>
						{payPeriodData.payrollStartDate &&
							payPeriodData.payrollEndDate && (
								<>
									<Row>
										{payPeriodData.payrollStartDate &&
											payPeriodData.payrollEndDate && (
												<>
													<Row>
														<Col span={24}>
															<div
																className={
																	styles[
																		'diff-days-label'
																	]
																}
															>
																pay period will
																be created for :{' '}
																{dayjs(
																	payPeriodData.payrollEndDate
																)
																	.add(
																		1,
																		'days'
																	)
																	.diff(
																		dayjs(
																			payPeriodData.payrollStartDate
																		),
																		'day'
																	)}{' '}
																days
															</div>
														</Col>
													</Row>
												</>
											)}
									</Row>
								</>
							)}
						<Row gutter={24}>
							<Col span={24}>
								<div
									className={styles['field-container']}
									style={{
										marginTop: '6px',
									}}
								>
									<div
										className={
											styles['field-container-label']
										}
									>
										<label
											className={
												styles[
													'side-drawer-form__napsa--label'
												]
											}
										>
											{'Pay Period Name'}{' '}
											<span className="required-color">
												*
											</span>
										</label>
									</div>
									<InputField
										name="payPeriodName"
										value={payPeriodData.payPeriodName}
										label=""
										required={true}
										helperText="Pay period name required"
										onChange={(value) => {
											handleChange(
												value,
												'payPeriodName',
												true
											);
										}}
										// onBlur={(e: any) => handlePayPeriodNameBlur(e)}
										isError={formError.firstName}
										// disabled={
										// 	!payPeriodData.year ||
										// 	!payPeriodData.payrollStartDate ||
										// 	!payPeriodData.payrollEndDate
										// }
										disabled={true}
										placeholder="Pay period name"
									/>
								</div>
							</Col>
						</Row>
						<Form.Item
							name="workWeek"
							rules={[
								{
									validator: (_, value) =>
										selectedDays.length > 0
											? Promise.resolve()
											: Promise.reject(
													new Error(
														'Please select at least one day'
													)
											  ),
								},
							]}
							validateTrigger="onChange"
						>
							<div className={styles['field-container']}>
								<label
									className={
										styles['side-drawer-form__napsa--label']
									}
								>
									{'Select your work week'}{' '}
									<span className="required-color">*</span>
								</label>
								<span
									className={
										styles['side-drawer-form__notes']
									}
								>
									The days worked in a calendar week{' '}
								</span>
								<div className={styles['day-button-container']}>
									<Row gutter={24}>
										{daysOfWeek.map((day) => (
											<Col key={day}>
												<Button
													size="middle"
													className={`day-button ${
														selectedDays.includes(
															day
														)
															? 'selected'
															: 'default-btn'
													} }`}
													onClick={() =>
														handleDaySelect(day)
													}
												>
													{day}
												</Button>
											</Col>
										))}
									</Row>
								</div>
							</div>
						</Form.Item>
						<div className={styles['field-container']}>
							<div className={styles['field-container-label']}>
								<label
									className={
										styles['side-drawer-form__napsa--label']
									}
								>
									{'Pay your employee on'}{' '}
									<span className="required-color">*</span>
								</label>
							</div>
							<Radio.Group
								onChange={() => {}}
								value={selectedOption}
							>
								<Row>
									<Col span={24}>
										<Radio
											id="payOnLastDay"
											className={styles['pay-radio']}
											value="payOnLastDay"
										>
											The last working day of every month
										</Radio>
									</Col>
								</Row>
							</Radio.Group>
						</div>
						<span className={styles['side-drawer-form__notes']}>
							Note: When payday falls on a non-working day or a
							holiday, employees will get paid on the previous
							working day.
						</span>
						<Row gutter={24}>
							<Col span={24}>
								<div className={styles['field-container']}>
									<div
										className={
											styles['field-container-label']
										}
									>
										<label
											className={
												styles[
													'side-drawer-form__napsa--label'
												]
											}
										>
											{'Employee Pay Date'}{' '}
											<span className="required-color">
												*
											</span>
										</label>
									</div>
									<DatePickerField
										name="employeePayPeriodDate"
										value={payPeriodData.employeePayDate}
										label=""
										required={true}
										helperText="Employee pay date is required"
										onChange={(value) => {
											handleChange(
												value,
												'employeePayDate',
												true
											);
										}}
										isError={formError.employeePayDate}
										placeholder="Employee Pay Date"
										// disabled={
										// 	!payPeriodData.year ||
										// 	!payPeriodData.payrollStartDate ||
										// 	!payPeriodData.payrollEndDate
										// }
										disabled={true}
									/>
								</div>
							</Col>
						</Row>
					</div>
					<div className={styles['side-drawer-form__buttons']}>
						<Buttons buttons={buttons} />
					</div>
				</Form>
			</div>
			<Modal
				open={confirmModal}
				width={'1100px'}
				onCancel={() => setConfirmModal(false)}
				closable={false}
				style={{
					borderRadius: 0,
				}}
				title="Pay Period Information"
				footer={<Buttons buttons={ModalButtons} />}
			>
				<Typography.Text type="warning">
					Once you confirm, the pay period information below will be
					{isEdit ? ' updated' : ' created'} for the year{' '}
					{dayjs(payPeriodData.year).year()}.
				</Typography.Text>

				<Table
					dataSource={globalData}
					pagination={false}
					scroll={{ y: 'calc(100vh - 400px)' }}
				>
					<Column
						title="Pay period name"
						key="payPeriodName"
						dataIndex={'payPeriodName'}
						width={'30%'}
					/>
					<Column
						title="Working Days"
						key="workWeek"
						dataIndex={'workWeek'}
						width={'30%'}
					/>
					<Column
						title="Start date"
						key="payrollStartDate"
						dataIndex={'payrollStartDate'}
						width={'20%'}
						render={(text: string) => {
							return dayjs(text).format('DD/MM/YYYY');
						}}
					/>
					<Column
						title="End date"
						key="payrollEndDate"
						dataIndex={'payrollEndDate'}
						width={'20%'}
						render={(text: string) => {
							return dayjs(text).format('DD/MM/YYYY');
						}}
					/>
					<Column
						title="Total days"
						key="payrollEndDate"
						width={'10%'}
						render={(text: string, record: any) => {
							return dayjs(record.payrollEndDate)
								.add(1, 'days')
								.diff(dayjs(record.payrollStartDate), 'day');
						}}
					/>
				</Table>
			</Modal>
		</>
	);
};

export default AddPayPeriodSideDrawer;
