import { getApi } from 'apis';

const getEmployeeTimeActivities = async (data: any) => {
	return await getApi(`/timeActivities/details`, data);
};
const getEmployeeTimeActivitiesByStaffCode = async (data: any) => {
	return await getApi(`/timeActivities/details/monthly`, data);
};

export const timeActivitiesApi = {
	getEmployeeTimeActivities,
	getEmployeeTimeActivitiesByStaffCode,
};
