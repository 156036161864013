import { Form, Input } from 'antd';
import { postApi, putApi } from 'apis';
import Buttons from 'components/Global/Buttons';
import { FC, useState } from 'react';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';
import { SideDrawerBodyProps } from './types';

const AddCategoryCodeBody: FC<SideDrawerBodyProps> = (props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const { closeDrawerByAnimation, fetchCategoryCodes, editSelected } = props;

	const buttons = [
		{
			text: `Cancel`,
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: true,
			disabled: loading,
			onclick: () => {
				closeDrawerByAnimation();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
		{
			text: 'Save',
			isLoading: loading,
			className: 'btn-blue',
			isSubmit: true,
			disabled: false,
			onclick: () => {},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
	];
	const handleSubmit = async (values: any) => {
		if (editSelected) {
			let data: any = {
				categoryId: editSelected?.id,
				...values,
			};
			await updateCategoryCode(data);
		} else {
			await addCategoryCode(values);
		}
	};

	const updateCategoryCode = async (categoryCodeData: any) => {
		try {
			setLoading(true);
			const response = await putApi('/category', categoryCodeData);
			fetchCategoryCodes();
			closeDrawerByAnimation();
			let message =
				response?.data?.message ||
				'Category code updated successfully.';

			toastText(message, 'success');
		} catch (err: any) {
			let message = 'Something went wrong in updating category code.';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};
	const addCategoryCode = async (finalData: any) => {
		try {
			setLoading(true);
			const response = await postApi('/category', finalData);
			fetchCategoryCodes();
			closeDrawerByAnimation();
			let message =
				response?.data?.message ||
				'Category code created successfully.';

			toastText(message, 'success');
		} catch (err: any) {
			let message =
				'Something went wrong when creating to category code.';
			if (err.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<div className={styles['side-drawer-body']}>
				<Form
					name="basic"
					className={styles['side-drawer-form']}
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					style={{ maxWidth: 600 }}
					onFinish={handleSubmit}
					initialValues={editSelected}
					autoComplete="off"
				>
					<p className={styles['form-container-head-warning']}>
						<b>
							{' '}
							<sup>*</sup>
						</b>{' '}
						Indicated mandatory fields
					</p>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Category Code'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'code'}
							rules={[
								{
									required: true,
									message: 'Category code required',
									validateTrigger: 'onChange',
									validator: (_, value) =>
										value && value.trim() !== ''
											? Promise.resolve()
											: Promise.reject(
													'Please enter value'
											  ),
								},
								// {
								// 	max: 30,
								// 	message:
								// 		'Category code length must be less than 30 characters',
								// 	validateTrigger: 'onChange',
								// },
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__inputs']}>
						<label
							className={styles['side-drawer-form__role--label']}
						>
							{'Category Name'}{' '}
							<span className="required-color">*</span>
						</label>
						<Form.Item
							// className="side-drawer-form__role"
							name={'name'}
							rules={[
								{
									required: true,
									message: 'Category name required',
									validateTrigger: 'onChange',
									validator: (_, value) =>
										value && value.trim() !== ''
											? Promise.resolve()
											: Promise.reject(
													'Please enter value'
											  ),
								},
								// {
								// 	max: 30,
								// 	message:
								// 		'Category name length must be less than 30 characters',
								// 	validateTrigger: 'onChange',
								// },
							]}
							wrapperCol={{ span: 24 }}
						>
							<Input
								className="remove-antd-input-effect"
								maxLength={150}
								size="large"
								disabled={false}
								type={'text'}
								// defaultValue={
								// 	userProfileData[item?.name]
								// }
							/>
						</Form.Item>
					</div>
					<div className={styles['side-drawer-form__buttons']}>
						<Form.Item>
							<Buttons buttons={buttons} />
						</Form.Item>
					</div>
				</Form>
			</div>
		</>
	);
};

export default AddCategoryCodeBody;
