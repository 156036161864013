import { MailOutlined } from '@ant-design/icons';
import { timeLogsApi } from 'Api/timeLogs';
import { Button, Table } from 'antd';
import Column from 'antd/es/table/Column';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { invalidText, toastText } from 'utils/utils';
import AddPendingLogsModal from './AddPendingLogsModal';
import './index.scss';
type Props = {
	// date: any;
	supervisorId: string;
	selectedShift: string;
	holidays: any;
	handleRowClick: any;
};

const PendingTimeLogs = (props: Props) => {
	const { supervisorId, selectedShift, holidays } = props;

	const [isLoading, setIsLoading] = useState(false);
	const [totalRecords, setTotalRecords] = useState(0);
	const [pageSize, setPageSize] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [tableData, setTableData] = useState([]);

	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	const [selectedSupervisorIds, setSelectedSupervisorIds] = useState<any[]>(
		[]
	);
	const [isReminderLoading, setIsReminderLoading] = useState(false);

	const [selectedShiftDetails, setSelectedShiftDetails] = useState<any>({});
	const [shiftStartDate, setShiftStartDate] = useState<any>({});
	const [shiftEndDate, setShiftEndDate] = useState<any>({});
	const [isShiftDetailsModalOpen, setIsShiftModalOpen] = useState(false);
	const [shiftDetails, setShiftDetails] = useState(null);
	const [isAddLogsModalOpen, setIsAddLogsModalOpen] = useState(false);
	const [selectedDate, setSelectedDate] = useState<any>(null);
	const [selectedRowDate, setSelectedRowDate] = useState<any>(null);
	const [pendingDate, setPendingDate] = useState<any>(null);

	const handleRowClick = (date: any) => {
		setSelectedRowDate(date);
		// setIsAddLogsModalOpen(true);
	};
	const handleAddLogClick = (shiftData: any) => {
		setShiftStartDate(shiftData.startDate);
		setShiftEndDate(shiftData.endDate);
		setSelectedShiftDetails(shiftData);
		setSelectedDate(shiftData.date);
		setIsAddLogsModalOpen(true);
	};

	const tableChangeHandler = (
		pagination: any,
		filter: any,
		sorter: any
	) => {};

	const fetchPendingLogs = async () => {
		if (invalidText(selectedShift)) return;
		try {
			setIsLoading(true);

			const data: {
				selectedShiftId: string;
				// supervisorId?: string;
			} = {
				// startDate: dayjs(date[0]).format('YYYY-MM-DD'),
				// endDate: dayjs(date[1]).format('YYYY-MM-DD'),
				selectedShiftId: selectedShift,
			};

			// if (!invalidText(supervisorId)) {
			// 	data['supervisorId'] = supervisorId;
			// }

			const timeLogs = await timeLogsApi.getPendingTimeActivities(data);
			setTableData(timeLogs.data.data);
		} catch (error: any) {
			let message = 'Something went wrong in fetching employee details';

			if (error.response.data.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchPendingLogs();
	}, [selectedShift, supervisorId]);

	const rowSelection = {
		selectedRowKeys,
		onChange: (selectedKeys: React.Key[], selectedRows: any[]) => {
			setSelectedRowKeys(selectedKeys);
			const supervisorIds = selectedRows.map((row) => {
				return { supervisorId: row.supervisorId, date: row.date };
			});
			setSelectedSupervisorIds(supervisorIds);
		},
	};

	const handleButtonClick = async () => {
		try {
			setIsReminderLoading(true);
			const data = groupBySupervisorId(selectedSupervisorIds);

			const response = await timeLogsApi.sendEmailReminders({
				data: data,
			});
			const successMessage =
				response?.data?.message || 'Email sent successfully';
			toastText(successMessage, 'success');

			setSelectedRowKeys([]);
			setSelectedSupervisorIds([]);

			return response;
		} catch (error: any) {
			let message = 'Something went wrong in fetching employee details';

			if (error.response.data.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsReminderLoading(false);
		}
	};

	const groupBySupervisorId = (
		data: { supervisorId: string; date: string }[]
	) => {
		const groupedData: { supervisorId: string; dates: string[] }[] = [];

		data.forEach((item) => {
			const { supervisorId, date } = item;

			let supervisorEntry = groupedData.find(
				(entry) => entry.supervisorId === supervisorId
			);

			if (!supervisorEntry) {
				supervisorEntry = { supervisorId, dates: [] };
				groupedData.push(supervisorEntry);
			}

			supervisorEntry.dates.push(dayjs(date).format('YYYY-MM-DD'));
		});

		return groupedData;
	};
	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'flex-end',
					marginBottom: '10px',
				}}
			>
				{selectedRowKeys.length > 0 && (
					<Button
						onClick={handleButtonClick}
						style={{
							marginTop: '20px',
							marginRight: '10px',
							backgroundColor: '#FFF0F1',
							color: '#DF081A',
						}}
						loading={isReminderLoading}
					>
						<MailOutlined /> Send email reminder
					</Button>
				)}
			</div>
			<Table
				className="pending-logs"
				dataSource={tableData}
				pagination={false}
				onChange={tableChangeHandler}
				rowKey={(record: any) => record.id}
				loading={isLoading}
				//scroll={{ y: 'calc(100vh - 350px)' }}
				rowSelection={{ ...rowSelection, columnWidth: 1 }}
				onRow={(record) => ({
					onClick: () => handleRowClick(record.date),
				})}
			>
				<Column
					title="Date"
					dataIndex="date"
					key="date"
					className="bg-white"
					render={(value) => {
						return dayjs(value).format('DD/MM/YYYY');
					}}
					width="30%"
				/>
				{/* <Column
					title="Shift Name"
					dataIndex="shiftName"
					key="shiftName"
					className="bg-white"
					// render={(value) => {
					// 	return dayjs(value).format('DD/MM/YYYY');
					// }}
					width="19%"
				/> */}
				<Column
					title="Total Hours"
					dataIndex="totalHours"
					key="totalHours"
					className="bg-white"
					render={(value) => {
						return <span style={{ color: 'red' }}>----</span>;
					}}
					width="30%"
				/>

				<Column
					title={
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'start',
							}}
						>
							Attendance <br />{' '}
							<span
								style={{
									color: 'gray',
									fontWeight: 300,
									fontSize: '10px',
								}}
							>
								Present | Absent
							</span>
						</div>
					}
					dataIndex="attendance"
					key="attendance"
					className="bg-white"
					render={(value, record: any) => {
						return <span style={{ color: 'red' }}>----</span>;
					}}
					width="30%"
				/>
				<Column
					title="Action"
					dataIndex="action"
					key="action"
					className="bg-white"
					render={(record: any, data: any) => (
						<div
							style={{
								display: 'flex',
								gap: '2rem',
								alignItems: 'center',
							}}
						>
							<Button
								onClick={() => {
									setIsAddLogsModalOpen(true);
									setSelectedShiftDetails(selectedShift);
									setPendingDate(data.date);
								}}
								type="primary"
							>
								Add Log
							</Button>
						</div>
					)}
				/>
				{/* <Column
					title="Submitted By"
					dataIndex="supervisorName"
					key="supervisorName"
					// sorter={true}
					className="bg-white"
					width="19%"
				/> */}
			</Table>
			{isAddLogsModalOpen && (
				<AddPendingLogsModal
					holidays={holidays}
					isOpen={isAddLogsModalOpen}
					handleCancel={() => {
						setIsAddLogsModalOpen(false);
					}}
					fetchShifts={fetchPendingLogs}
					selectedShiftDetailsId={selectedShiftDetails}
					pendingDate={pendingDate}
				/>
			)}
		</>
	);
};

export default PendingTimeLogs;
