import { getApi, postApi } from 'apis';

const createDirectDeposit = async (data: any) => {
	return await postApi('/directDeposit', data);
};
const fetchDirectDepositByEmployeeId = async (id: string) => {
	return await getApi(`/directDeposit/get/${id}`);
};

const getLatestPaymentDetails = async () => {
	return await getApi('/directDeposit/latest');
};

const getPaymentHistory = async (query: any) => {
	return await getApi('/directDeposit/get-all-payments', query);
};
const saveCashPayment = async (data: any) => {
	return await postApi('/directDeposit/cash', data);
};

export const directDepositApi = {
	createDirectDeposit,
	fetchDirectDepositByEmployeeId,
	getLatestPaymentDetails,
	getPaymentHistory,
	saveCashPayment,
};
