import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import DatePickerField from 'components/Global/DatePicker';
import SearchComponent from 'components/Global/SearchComponent';
import styles from './index.module.scss';
// import { useContext } from 'react';
// import { PermissionContext } from 'components/Global/AuthLayout';

type Props = {
	searchValue: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => void;
	dateValue: any;
	handleDatePicker: any;
	isLoading: any;
	fetchTimeSheets: any;
};

const TimeSheetsTableHeader = (props: Props) => {
	const {
		searchValue,
		handleSearch,
		handleSearchApi,
		handleDatePicker,
		dateValue,
		isLoading,
		fetchTimeSheets,
	} = props;
	// const permissions = useContext(PermissionContext);

	return (
		<div className={styles['employee-header']}>
			<Space>
				<SearchComponent
					className={styles['employee-header-item']}
					placeHolder="Search here..."
					prefixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchValue}
					size="large"
				/>
				<DatePickerField
					name={'dateFilter'}
					value={dateValue}
					required={false}
					picker="month"
					isError={false}
					onChange={handleDatePicker}
				/>
			</Space>
			<div className={styles['employee-header__buttons']}>
				<Tooltip title="Refresh all the Time Sheets">
					<SyncOutlined
						style={{
							fontSize: '20px',
							cursor: isLoading ? 'not-allowed' : 'pointer',
							color: '#584495',
							transition: 'transform 0.3s',
							marginRight: '10px',
						}}
						onClick={!isLoading ? fetchTimeSheets : undefined}
					/>
				</Tooltip>
			</div>
		</div>
	);
};

export default TimeSheetsTableHeader;
