import { Space, Tooltip } from 'antd';
import styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import SearchComponent from 'components/Global/SearchComponent';

type Props = {
	button: any;
	fetchAllPayGroups: () => void;
	isLoading: boolean;
	handleSearch: (value: string) => void;
	searchText: string;
};

const PayGroupHeader = (props: Props) => {
	const permissionContext = useContext(PermissionContext);
	const isAdd =
		permissionContext.allowedPermissions.includes('Add_Pay_Group');
	const { button, fetchAllPayGroups, isLoading, handleSearch, searchText } =
		props;

	return (
		<div className={styles['employee-header']}>
			<Space>
				<SearchComponent
					className={styles['employee-header-item']}
					placeHolder="Search here..."
					prefixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={() => {}}
					value={searchText}
					size="large"
				/>
			</Space>
			<Space>
				<Tooltip title="Refresh all the Pay Group">
					<SyncOutlined
						style={{
							fontSize: '20px',
							cursor: isLoading ? 'not-allowed' : 'pointer',
							color: '#584495',
							transition: 'transform 0.3s',
							marginRight: '10px',
						}}
						onClick={!isLoading ? fetchAllPayGroups : undefined}
					/>
				</Tooltip>
				{isAdd && <Buttons buttons={button} />}
			</Space>
		</div>
	);
};

export default PayGroupHeader;
