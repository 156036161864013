import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';
import CheckBox from 'components/Global/Checkbox';
import Buttons from 'components/Global/Buttons';
import styles from './index.module.scss';
import { toastText } from 'utils/utils';
import { getApproveRequestApi } from 'Api/approveRequest';
import { LoadingOutlined } from '@ant-design/icons';

type TaxationItem = {
	id: number;
	label: string;
	fieldName: string;
	sectionName: string;
	moduleName: string;
	sendApproval: boolean;
};

type Props = {
	data: TaxationItem[];
	handleTaxationFields: (data: any) => void;
};

const TaxationApproval = () => {
	const [taxationFields, setTaxationFields] = useState<TaxationItem[]>([]);
	const [loading, setLoading] = useState(false);
	const [isOnSaveLoading, setIsOnSaveLoading] = useState(false);
	const handleCheckboxChange = (id: number, checked: boolean) => {
		const updatedData = taxationFields.map((item: any) =>
			item.id === id ? { ...item, sendApproval: checked } : item
		);
		setTaxationFields(updatedData);
	};

	const handleSave = async () => {
		const checkedItems = taxationFields.filter((item) => item.sendApproval);
		if (checkedItems.length > 0) {
			setIsOnSaveLoading(true);
			try {
				const data = await getApproveRequestApi.updateApprovalFields({
					fields: checkedItems,
				});
				toastText(
					'Approval fields has been updated successfully.',
					'success'
				);
			} catch (err) {
				toastText(
					'Something went wrong in updating taxation fields',
					'error'
				);
			}
			setIsOnSaveLoading(false);
		}
	};

	const myButtons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			disabled: isOnSaveLoading,
			isSubmit: false,
			onclick: () => {},
		},
		{
			text: 'Save',
			isLoading: isOnSaveLoading,
			className: 'btn-blue',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			disabled: false,
			isSubmit: true,
			onclick: handleSave,
		},
	];

	const fetchTaxationFields = async () => {
		setLoading(true);
		try {
			const data = await getApproveRequestApi.getApprovalFields({
				moduleName: 'EMPLOYEE',
				sectionName: 'Taxation Details',
			});

			setTaxationFields(data.data.data);
		} catch (err) {
			toastText(
				'Something went wrong in fetching employee fields',
				'error'
			);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchTaxationFields();
	}, []);

	return (
		<>
			{loading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '70vh',
					}}
				>
					<LoadingOutlined
						style={{ fontSize: '100px', color: '#584495' }}
					/>
				</div>
			) : (
				<div className={styles['employeeApprovalContainer']}>
					<div
						className={
							styles['employeeApprovalContainer--checkbox']
						}
					>
						<Row gutter={[16, 16]}>
							{taxationFields.map((checkbox) => (
								<Col
									key={checkbox.id}
									xs={24}
									sm={12}
									md={12}
									lg={8}
								>
									<CheckBox
										label={checkbox.label}
										checked={checkbox.sendApproval}
										onChange={(checked) =>
											handleCheckboxChange(
												checkbox.id,
												checked
											)
										}
									/>
								</Col>
							))}
						</Row>
					</div>
					<div className={styles.buttonsContainer}>
						<Buttons buttons={myButtons}></Buttons>
					</div>
				</div>
			)}
		</>
	);
};

export default TaxationApproval;
