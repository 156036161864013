import { Col, Modal, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { loanRequestApi } from 'Api/loanRequest';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import InputNumberField from 'components/Global/InputNumberField';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { formatToDateOnly, toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';

type UpdateLoanCreditModalProps = {
	visible: boolean;
	onOk: () => void;
	onCancel: () => void;
	title?: string;
	okText?: string;
	cancelText?: string;
	updateEffectiveDate: any;
	fetchLoanRequests: any
};

const UpdateLoanCreditModal: React.FC<UpdateLoanCreditModalProps> = ({
	visible,
	onOk,
	onCancel,
	title = 'Update Loan Credit',
	okText = 'Update',
	cancelText = 'Cancel',
	updateEffectiveDate,
	fetchLoanRequests
}) => {
	const [updateLoading, setIsUpdateloading] = useState<boolean>(false);
	const myButtons = [
		{
			text: 'Cancel',
			disabled: updateLoading,
			className: 'secondary-button',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isLoading: false,
			isSubmit: false,
			onclick: () => {
				onCancel();
			},
		},
		{
			text: 'Save',
			isLoading: updateLoading,
			className: 'btn-blue',
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			disabled: false,
			isSubmit: true,
			onclick: handleSubmit,
		},
	];

	const [monthlyDeductions, setMonthlyDeductions] = useState<any[]>([]);
	const [date, setDate] = useState<any>(dayjs());

	const handleDate = (value: any) => {
		setDate(value);
	};

	useEffect(() => {
		if (
			updateEffectiveDate?.amount &&
			updateEffectiveDate?.installment &&
			date
		) {
			calculateMonthlyDeductions(
				updateEffectiveDate?.amount,
				updateEffectiveDate?.installment,
				date
			);
		}
	}, [date, updateEffectiveDate?.amount, updateEffectiveDate?.installment]);

	const calculateMonthlyDeductions = (
		amount: string | number | null,
		installments: string | number | null,
		startDate: string | null
	) => {
		if (amount === null || installments === null || startDate === null)
			return;

		const amountNumber =
			typeof amount === 'number' ? amount : parseFloat(amount as string);
		const installmentsNumber =
			typeof installments === 'number'
				? installments
				: parseInt(installments as string, 10);

		if (isNaN(amountNumber) || isNaN(installmentsNumber)) return;

		const startMonth = dayjs(startDate);
		let deductions = [];
		const deductionPerMonth = amountNumber / installmentsNumber;

		for (let i = 0; i < installmentsNumber; i++) {
			const month = startMonth.add(i, 'month').format('MMM - YYYY');
			deductions.push({
				key: i + 1,
				month: month,
				amount: deductionPerMonth.toFixed(2),
			});
		}

		setMonthlyDeductions(deductions);
	};

	async function handleSubmit() {

		try {
			setIsUpdateloading(true);
			const data = {
				id: updateEffectiveDate?.id,
				effectiveStartDate: formatToDateOnly(date as string),
			};
			await loanRequestApi.updateEffectiveDate(data);
			toastText('loan credited successfully', 'success');
			fetchLoanRequests();

			onCancel();
		} catch (error) {
			console.error('Failed to update loan credit:', error);
		} finally {
			setIsUpdateloading(false);
		}
	}

	return (
		<Modal
			title={title}
			open={visible}
			className='updateLoanCreditModal'
			closable={false}
			maskClosable={true}
			onCancel={onCancel}
			okText={okText}
			width={600}
			cancelText={cancelText}
			footer={<Buttons buttons={myButtons} />}
		>
			<Row gutter={16}>
				<Col span={24} className={`${styles['col']} margin-top-10`}>
					<DatePickerField
						name="effectiveDate"
						value={date}
						label="Effective Date"
						required={true}
						helperText="Effective Date is required"
						isError={false}
						onChange={handleDate}
					/>
				</Col>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<InputNumberField
						name="installment"
						value={updateEffectiveDate?.installment}
						label="Repayment Installments"
						required={false}
						disabled={true}
						helperText="Repayment Installments required"
						onChange={() => { }}
						isError={false}
					/>
				</Col>
				<Col span={12} className={`${styles['col']} margin-top-10`}>
					<InputNumberField
						name="amount"
						value={updateEffectiveDate?.amount}
						label="Amount"
						required={false}
						disabled={true}
						helperText="Amount required"
						onChange={() => { }}
						suffix={'K'}
						isError={false}
					/>
				</Col>
				{updateEffectiveDate?.amount &&
					updateEffectiveDate?.installment ? (
					<Col
						span={24}
						className={styles['title-monthly-deduction']}
					>
						<Title level={5}>Monthly Deduction</Title>
						<div className={styles['table-container']}>
							<div
								className={`${styles['table-header']} ${styles['table-row']}`}
							>
								<div className={styles['table-cell']}>
									Month
								</div>
								<div className={styles['table-cell']}>
									Amount
								</div>
							</div>
							{monthlyDeductions.map((item) => (
								<div
									key={item.key}
									className={`${styles['table-row']}`}
								>
									<div className={styles['table-cell']}>
										{item.month}
									</div>
									<div className={styles['table-cell']}>
										{item.amount}
									</div>
								</div>
							))}
						</div>
					</Col>
				) : (
					<></>
				)}
			</Row>
		</Modal>
	);
};

export default UpdateLoanCreditModal;
