import {
	CheckOutlined,
	CloseOutlined,
	EditOutlined,
	SyncOutlined,
} from '@ant-design/icons';
import { payrollApi } from 'Api/payroll';
import { Button, Divider, Modal, Select, Table, Tag, Tooltip } from 'antd';
import Column from 'antd/es/table/Column';
import Title from 'antd/es/typography/Title';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { bankMapping } from 'utils/mappings';
import {
	Paid,
	PaymentUnderProcessWithText,
	PendingForApproval,
	RejectedByAccounts
} from 'utils/svgs';
import { decrypt, formatNumber, invalidText, toastText } from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';
type Props = {
	isOpen: boolean;
	handleCancel: () => void;
	handleProceedToPayment: () => void;
	payrollId: string;
	selectedBankId: string;
	currency: string;
	selectedBankData: any;
};

const AddPreviewModal = (props: Props) => {
	const {
		isOpen,
		handleCancel,
		payrollId,
		selectedBankId,
		currency,
		selectedBankData,
	} = props;

	const { data } = useSelector((state: any) => state?.userProfile);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
	const [employeeData, setEmployeeData] = useState([]);
	const [selectedStatus, setSelectedStatus] = useState<{
		[key: string]: string;
	}>({});
	const [isEditing, setIsEditing] = useState<{ [key: string]: boolean }>({});
	const [isSaveLoading, setIsSaveLoading] = useState<{
		[key: string]: boolean;
	}>({});

	const handleEditClick = (recordId: string, status: string) => {
		if (status === 'PROCESSING') {
			toastText('Cannot edit processing status', 'warning');
			return;
		}
		setIsEditing((prev) => ({
			...prev,
			[recordId]: !prev[recordId],
		}));
	};

	const handleSaveClick = async (recordId: string) => {
		if (!selectedStatus[recordId]) {
			toastText('Please select a valid status', 'error');
			return;
		}

		setIsSaveLoading((prev) => ({ ...prev, [recordId]: true }));
		try {
			const data = {
				payrollId,
				paymentId: recordId,
				status: selectedStatus[recordId],
			};

			await payrollApi.changePaymentStatus(data);

			setEmployeeData((emp: any) => {
				return emp.map((item: any) => {
					if (item.id === recordId) {
						return {
							...item,
							status: selectedStatus[recordId],
						};
					}
					return item;
				});
			});

			toastText('Status saved successfully', 'success');
		} catch (error) {
			toastText('Something went wrong in saving status', 'error');
		} finally {
			setIsEditing((prev) => ({
				...prev,
				[recordId]: false,
			}));
			setIsSaveLoading((prev) => ({ ...prev, [recordId]: false }));
		}
	};

	const handleStatusChange = (recordId: string, value: string) => {
		if (!value) {
			setSelectedStatus((prev) => ({
				...prev,
				[recordId]: 'UNKNOWN',
			}));
			return;
		}
		setSelectedStatus((prev) => ({
			...prev,
			[recordId]: value,
		}));
	};

	const handleProceedToPayment = async () => {
		try {
			setIsButtonLoading(true);
			const paymentIds = employeeData.map((item: any) => item.id);

			let response;

			if (payrollId) {
				if (selectedBankData?.bankType === 'STANBIC_BANK') {
					response = await payrollApi.proceedToPaymentForStanbic({
						payrollId: payrollId,
						companyBankId: selectedBankId,
						paymentIds,
					});
				} else {
					response = await payrollApi.proceedToPayment({
						payrollId: payrollId,
						companyBankId: selectedBankId,
						paymentIds,
					});
					// response = await payrollApi.fcbProceedToPaymentQueue({
					// 	payrollId: payrollId,
					// 	companyBankId: selectedBankId,
					// 	paymentIds,
					// });
				}

				fetchEmployeeList(payrollId);
				toastText(
					response.data.message ||
						'Bank transfer initiated successfully',
					'success'
				);
			} else {
				toastText('Something went wrong in getting payrollId', 'error');
			}
		} catch (err: any) {
			let message = 'Something went wrong in bank transfer';
			if (err?.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsButtonLoading(false);
		}
	};

	const fetchEmployeeList = async (id: string) => {
		try {
			setIsLoading(true);
			const response = await payrollApi.getPaymentForPayrollDetails(id);

			setEmployeeData(response.data.data);
		} catch (err: any) {
			let message = 'Something went wrong in fetching employee details';
			if (err?.response?.data?.message) {
				message = err.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (payrollId && isOpen) {
			fetchEmployeeList(payrollId);
		}
	}, [payrollId, isOpen]);

	return (
		<Modal
			open={isOpen}
			closable={false}
			onCancel={handleCancel}
			centered
			rootClassName="addPaymentModalRoot"
			style={{
				minWidth: '1300px',
			}}
			footer={
				<div style={{ display: 'flex', justifyContent: 'flex-start' }}>
					<Button key="back" onClick={handleCancel}>
						Back
					</Button>
					{employeeData &&
						employeeData.length > 0 &&
						!employeeData.every(
							(emp: any) => emp.status === 'SUCCESS'
						) && (
							<Button
								key="proceed"
								type="primary"
								disabled={isLoading}
								onClick={handleProceedToPayment}
								style={{ marginLeft: '10px' }}
								loading={isButtonLoading}
							>
								Proceed to Payment
							</Button>
						)}
				</div>
			}
		>
			<div className={`${styles['modal']} modal`}>
				<div className={styles['modal-header']}>
					<Title level={4}>
						<span>
							Review the payment details for {employeeData.length}{' '}
							{employeeData.length === 1
								? 'employee'
								: 'employees'}{' '}
							and click Proceed.
						</span>
					</Title>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
							gap: '20px',
						}}
					>
						<Tooltip title="Refresh employee list">
							<SyncOutlined
								style={{
									fontSize: '20px',
									cursor: isLoading
										? 'not-allowed'
										: 'pointer',
									color: '#584495',
									transition: 'transform 0.3s',
									marginRight: '10px',
								}}
								onClick={
									!isLoading
										? () => fetchEmployeeList(payrollId)
										: undefined
								}
							/>
						</Tooltip>

						<div>
							<Button
								icon={<CloseOutlined />}
								onClick={handleCancel}
							/>
						</div>
					</div>
				</div>
				<Divider
					style={{
						margin: '12px 0 ',
						padding: '0',
					}}
				/>
				<div className={styles['company-account-details']}>
					<p className={styles['details']}>Company Account Details</p>
					<br />
				</div>
				<div className={styles['company-account-details']}>
					<div>
						<p>
							Account Name :{' '}
							<span className={styles['details']}>
								{' '}
								{selectedBankData?.accountName}
							</span>
						</p>
					</div>
					<div>
						<p>
							{' '}
							Bank Type :
							<span className={styles['details']}>
								{' '}
								{bankMapping[selectedBankData?.bankType]?.name}
							</span>
						</p>
					</div>

					{selectedBankData?.accountNumber && (
						<div>
							<p>
								{' '}
								Account Number :{' '}
								<span className={styles['details']}>
									{decrypt(selectedBankData?.accountNumber)}
								</span>
							</p>
						</div>
					)}
				</div>
				<div
					className={styles['modal-body']}
					style={{ marginBottom: 0 }}
				>
					<Table
						dataSource={employeeData}
						scroll={{
							y: 'calc(100vh - 400px)',
						}}
						rowKey={(record: any) => record.id}
						pagination={false}
						style={{
							marginTop: 2,
						}}
						loading={isLoading}
					>
						<Column
							title="Employee Name"
							dataIndex="employeeName"
							key="employeeName"
							className="bg-white"
							width={90}
							fixed="left"
							render={(text: string, record: any) => (
								<span>{`${text} | ${record.employeeCode}`}</span>
							)}
						/>
						{/* <Column
							title="Employee Code"
							dataIndex="employeeCode"
							key="employeeCode"
							className="bg-white"
							width={'10%'}
						/> */}
						<Column
							title="Bank"
							dataIndex="bank"
							key="bank"
							className="bg-white"
							width={60}
							render={(value: string) => {
								return bankMapping[value]?.name ?? '';
							}}
						/>
						<Column
							title="Bank Account Number"
							dataIndex="accountNumber"
							key="accountNumber"
							className="bg-white"
							width={75}
							render={(value: string) => {
								return !invalidText(value)
									? decrypt(value)
									: '';
							}}
						/>
						<Column
							title="Sort Code"
							dataIndex="sortCode"
							key="sortCode"
							className="bg-white"
							width={40}
						/>
						<Column
							title="Amount"
							dataIndex="amount"
							key="amount"
							className="bg-white"
							width={60}
							render={(value: string) => {
								return formatNumber(
									Number(value),
									data?.CompanyCurrencies,
									currency === 'USD' ? true : false
								);
							}}
						/>

						{selectedBankData?.bankType === 'STANBIC_BANK' ? (
							<></>
						) : (
							<>
								<Column
									title="Account Verified"
									dataIndex="accountVerified"
									key="accountVerified"
									className="bg-white"
									width={60}
									render={(value: string, record: any) => {
										if (record.bank === 'STANBIC_BANK') {
											return (
												<span
													style={{
														display: 'flex',
														width: '100%',
														justifyContent:
															'center',
													}}
												>
													<CheckOutlined
														style={{
															color: 'green',
														}}
													/>
												</span>
											);
										} else {
											return (
												<span
													style={{
														display: 'flex',
														width: '100%',
														justifyContent:
															'center',
													}}
												>
													{value ? (
														<CheckOutlined
															style={{
																color: 'green',
															}}
														/>
													) : (
														<CloseOutlined
															style={{
																color: 'red',
															}}
														/>
													)}
												</span>
											);
										}
									}}
								/>
							</>
						)}

						<Column
							title="Status"
							dataIndex="status"
							key="status"
							width={'75px'}
							className={styles['statusColumn'] + ' bg-white'}
							render={(value: string, record: any) => {
								let statusText = '';
								let statusClass = '';
								let StatusIcon = null;

								const currentStatus =
									selectedStatus[record.id] || value;

								switch (currentStatus) {
									case 'PENDING':
										statusText = 'Pending';
										statusClass = styles['status-pending'];
										StatusIcon = <PendingForApproval />;
										break;
									case 'FAILED':
										statusText = 'Failed';
										statusClass = styles['status-rejected'];
										StatusIcon = <RejectedByAccounts />;
										break;
									case 'SUCCESS':
										statusText = 'Success';
										statusClass = styles['status-success'];
										StatusIcon = <Paid />;
										break;
									case 'PROCESSING':
										statusText = '';
										statusClass =
											styles['status-processing'];
										StatusIcon = (
											<PaymentUnderProcessWithText />
										);
										break;
									default:
										statusText = 'Unknown Status';
										statusClass = styles['status-unknown'];
										StatusIcon = null;
								}
								return (
									<div className={styles['statusContainer']}>
										{isEditing[record.id] ? (
											<div
												className={
													styles['editingContainer']
												}
											>
												<Select
													value={currentStatus}
													onChange={(value) =>
														handleStatusChange(
															record.id,
															value
														)
													}
													style={{
														width: 120,
														marginRight: 8,
													}}
													placeholder="Select Status"
												>
													<Select.Option value="PENDING">
														<div
															className={
																styles['status']
															}
														>
															<PendingForApproval />
															<span>Pending</span>
														</div>
													</Select.Option>
													<Select.Option value="SUCCESS">
														<div
															className={
																styles['status']
															}
														>
															<Paid />
															<span>Success</span>
														</div>
													</Select.Option>
												</Select>
												<Button
													size="small"
													type="primary"
													loading={
														isSaveLoading[record.id]
													}
													onClick={() =>
														handleSaveClick(
															record.id
														)
													}
													className={
														styles['saveButton']
													}
													disabled={
														!selectedStatus[
															record.id
														]
													}
												>
													Save
												</Button>
											</div>
										) : (
											<div
												className={
													styles['statusDisplay']
												}
											>
												<div
													className={
														styles['statusAndEdit']
													}
												>
													<Tag
														className={`${styles['statusTag']} ${statusClass}`}
														bordered={false}
													>
														<span
															className={
																styles[
																	'statusIcon'
																]
															}
														>
															{StatusIcon}
														</span>
														<span>
															{statusText}
														</span>
													</Tag>
													{record.status !==
														'SUCCESS' &&
														record.status !==
															'PROCESSING' && (
															<Button
																size="small"
																type="link"
																onClick={() =>
																	handleEditClick(
																		record.id,
																		record.status
																	)
																}
																className={
																	styles[
																		'dropdownTrigger'
																	]
																}
																style={{
																	marginLeft: 8,
																}}
															>
																<EditOutlined />
															</Button>
														)}
												</div>
											</div>
										)}
									</div>
								);
							}}
						/>
					</Table>
				</div>
			</div>
		</Modal>
	);
};

export default AddPreviewModal;
