import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { Select, Space, Tooltip } from 'antd';
import SearchComponent from 'components/Global/SearchComponent';
import styles from './index.module.scss';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';

type Props = {
	button: any;
	dateValue: any;
	handleDatePicker: any;
	searchText: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => void;
	fetchHoliday:any;
	isLoading:any;
};

const HolidayHeader = (props: Props) => {
	const {
		handleDatePicker,
		dateValue,
		button,
		searchText,
		handleSearch,
		handleSearchApi,
		isLoading,
		fetchHoliday,
	} = props;
	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes('Add_Holidays');
	return (
		<div className={styles['employee-header']}>
			<Space>
				<SearchComponent
					className={styles['employee-header-item']}
					placeHolder="Search here..."
					prefixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchText}
					size="large"
				/>

				<DatePickerField
					name={'dateFilter'}
					value={dateValue}
					required={false}
					picker="year"
					isError={false}
					onChange={handleDatePicker}
				/>
			</Space>

			{isAdd && (
				<Space>
						<div className={styles['employee-header__buttons']}>
					<Tooltip title="Refresh all the Holidays">
						<SyncOutlined
							style={{
								fontSize: '20px',
								cursor: isLoading ? 'not-allowed' : 'pointer',
								color: '#584495',
								transition: 'transform 0.3s',
								marginRight: '10px',
							}}
							onClick={
								!isLoading
									? fetchHoliday
									: undefined
							}
						/>
					</Tooltip>
					<Buttons buttons={button} />
					</div>
				</Space>
			)}
		</div>
	);
};

export default HolidayHeader;
