import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { formatNumber } from 'utils/utils';
import styles from './index.module.scss';

const { Column } = Table;

type Props = {
	// tableData: EmployeeTableInterface[];
	tableData: any;
	totalRecords: number;
	currentPage: number;
	pageSize: number;
	tableChangeHandler: (pagination: any, filter: any, sorter: any) => void;
	isLoading: boolean;
};

const TimeActivitiesTable = (props: Props) => {
	const {
		tableData,
		totalRecords,
		currentPage,
		pageSize,
		tableChangeHandler,
		isLoading,
	} = props;

	const { data } = useSelector((state: any) => state?.userProfile);
	return (
		<div className={styles['dynamic-table']}>
			<Table
				dataSource={tableData}
				// scroll={{ y: 'calc(100vh - 360px)' }}
				pagination={{
					total: totalRecords,
					current: currentPage,
					pageSize: pageSize,
					showSizeChanger: false,
					// onShowSizeChange: pageSizeHandler,
					pageSizeOptions: [10, 20, 50, 100, 200],
				}}
				onChange={tableChangeHandler}
				rowKey={(record) => record.id}
				loading={isLoading}
			>
				<Column
					title="Employee Name | Code"
					dataIndex="optionalEmployeeId"
					key="optionalEmployeeId"
					className="bg-white"
					sorter={true}
					render={(text, record: any) => (
						<>
							<span>
								{record.firstName} {record.lastName}
							</span>{' '}
							|{' '}
							<span className="color-purple">
								{record?.optionalEmployeeId}
							</span>
						</>
					)}
				/>
				<Column
					title={
						<>
							<div>Salary Rate</div>
							{/* <div
								style={{
									color: 'lightgrey',
									fontSize: 'small',
								}}
							>
								In Kwacha
							</div> */}
						</>
					}
					dataIndex="monthlySalary"
					className="bg-white"
					key="monthlySalary"
					// sorter={true}
					render={(value: string, record: any) => {
						// Check if the current row's isUSD value is true
						if (record.isUSD) {
							return formatNumber(
								Number(value),
								data?.CompanyCurrencies,
								true // USD formatting
							);
						} else {
							return formatNumber(
								Number(value),
								data?.CompanyCurrencies // Local currency formatting
							);
						}
					}}
					// render={(value: string) => {
					// 	return ` ${value ? `${value}` : 'N/A'}`;
					// }}
				/>
				<Column
					title="Regular Hours"
					dataIndex="totalRegularHours"
					key="totalRegularHours"
					className="bg-white"
					// sorter={true}
					// render={(value: string) =>
					// 	dayjs(value).format('DD/MM/YYYY')
					// }
				/>
				<Column
					title="Weekday OT"
					dataIndex="weekdaysOT"
					className="bg-white"
					key="weekdaysOT"
					// sorter={true}
				/>
				<Column
					title="Sunday OT"
					dataIndex="sundayOT"
					className="bg-white"
					key="sundayOT"
					// sorter={true}
				/>
				<Column
					title="Shift Difference"
					dataIndex="totalTimeShiftHours"
					className="bg-white"
					key="totalTimeShiftHours"
					// sorter={true}
				/>
				<Column
					title="Total Hours"
					dataIndex="totalHours"
					key="totalHours"
					className="bg-white"
					render={(value: string) => {
						return <span className="color-purple"> {value}</span>;
					}}
				/>
				{/* <Column title="Leaves" dataIndex="leaves" key="leaves" /> */}
			</Table>
		</div>
	);
};

export default TimeActivitiesTable;
