import Buttons from 'components/Global/Buttons';
import React from 'react';
import styles from './index.module.scss';
import { PermissionContext } from 'components/Global/AuthLayout';
import { useContext } from 'react';
import Tooltip from 'antd/es/tooltip';
import { SyncOutlined } from '@ant-design/icons';

type headerProps = {
	sidebarDrawerOpen: () => void;
	NapsaExists: boolean;
	isLoading: boolean;
	fetchLatestNapsaData :any;
};

const NapsaHeader = ({
	sidebarDrawerOpen,
	NapsaExists,
	isLoading,
	fetchLatestNapsaData,
}: headerProps) => {
	const permissionContext = useContext(PermissionContext);
	const isAdd = permissionContext.allowedPermissions.includes(
		'Add_Statutory_Components'
	);
	const buttonTitle = NapsaExists ? 'Edit NAPSA' : 'Add NAPSA';
	const buttons = [
		{
			text: buttonTitle,
			isLoading: false,
			className: 'btn-blue',
			isSubmit: true,
			onclick: () => {
				sidebarDrawerOpen();
			},
			disabled: isLoading,
		},
	];
	return (
		<div className={styles['napsa-header']}>
			<p>NAPSA</p>
			{isAdd && (
				<div className={styles['napsa-header__buttons']}>
					<Tooltip title="Refresh all the NAPSA">
						<SyncOutlined
							style={{
								fontSize: '20px',
								cursor: isLoading ? 'not-allowed' : 'pointer',
								color: '#584495',
								transition: 'transform 0.3s',
								marginRight: '10px',
							}}
							onClick={
								!isLoading ? fetchLatestNapsaData : undefined
							}
						/>
					</Tooltip>
					<Buttons buttons={buttons} />
				</div>
			)}
		</div>
	);
};

export default NapsaHeader;
