import { Checkbox, Col, Modal, Row } from 'antd';
import Title from 'antd/es/typography/Title';
import { shiftsApi } from 'Api/shifts';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import InputField from 'components/Global/InputField';
import SelectDropdown from 'components/Global/SelectDropdown';
import TimePickerField from 'components/Global/TimePicker';
import InputHoursField from 'components/InputHoursField';
import dayjs, { Dayjs } from 'dayjs';
import { ShiftDetailsInterface } from 'interfaces/employee.interface';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
	formatToDateOnly,
	hasFormError,
	invalidText,
	minutesToTimeString,
	timeStringToMinutes,
	toastText,
	validateFormData,
} from 'utils/utils';
import { fetchConstantDropdownAction } from '../../../redux/actions/constantDropdownAction';
import styles from './index.module.scss';
import './index.scss';
import { employeeApi } from 'Api/employee';
import InputShiftHoursField from 'components/InputHoursField';
type Props = {
	closeModal: () => void;
	isModalOpen: boolean;
	editShift: any;
	fetchShifts: () => void;
	isViewOnly: boolean;
};

const CreateShiftModal = (props: Props) => {
	const {
		isModalOpen,
		closeModal,
		editShift,
		fetchShifts,
		isViewOnly = false,
	} = props;

	const dispatch = useDispatch<AppDispatch>();
	const constantDropdownOptions = useSelector(
		(state: any) => state.constantDropdown.data
	);

	const [shiftDetails, setShiftDetails] = useState<ShiftDetailsInterface>({
		startDate: '',
		endDate: '',
		startTime: dayjs(new Date()),
		endTime: dayjs(new Date()),
		branchId: null,
		shiftName: '',
		employeeIds: [],
		supervisorId: null,
		notes: null,
		defaultRegularHours: '08:00',
		defaultOverTimeHours: '00:00',
		isForeMan: null,
	});

	const [hasError, setHasError] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [employeeOptions, setEmployeeOptions] = useState<any[]>([]);
	const [isNightShift, setIsNightShift] = useState(false);
	const [filteredEmployees, setFilteredEmployees] = useState<any>([]);

	const [formError, setFormError] = useState<any>({
		startDate: false,
		endDate: false,
		startTime: false,
		endTime: false,
		branchId: false,
		shiftName: false,
		employeeIds: false,
		supervisorId: false,
		notes: false,
		isForeMan: false,
	});

	const [inputDefaultRegularHours, setInputDefaultRegularHours] =
		useState('08:00');

	const handleCloseModal = async () => {
		setShiftDetails({
			startDate: '',
			endDate: '',
			startTime: dayjs(new Date()),
			endTime: dayjs(new Date()),
			branchId: null,
			shiftName: '',
			employeeIds: [],
			supervisorId: null,
			notes: null,
			defaultRegularHours: '08:00',
			defaultOverTimeHours: '00:00',
			isForeMan: null,
		});
		setFormError({
			startDate: false,
			endDate: false,
			startTime: false,
			endTime: false,
			branchId: false,
			shiftName: false,
			employeeIds: false,
			supervisorId: false,
			notes: false,
			defaultRegularHours: false,
			isForeMan: false,
			// defaultOverTimeHours: false,
		});
		setHasError(false);
		closeModal();
	};

	const handleChange = (
		value: string | number | null | string[] | Dayjs | any,
		name: string,
		required: boolean,
		regex?: RegExp | null
	) => {
		if (required && typeof value === 'string') {
			setHasError(invalidText(value));
		}

		if (required && Array.isArray(value) && value.length === 0) {
			setHasError(true);
		} else {
			setHasError(false);
		}

		if (name === 'branchId') {
			setShiftDetails((prev) => {
				return {
					...prev,
					employeeIds: [],
				};
			});
		}

		if (name === 'startDate') {
			// setShiftDetails((prev) => {...prev, endDate: value})
			setShiftDetails((prev) => {
				return {
					...prev,
					startTime: dayjs(value as string),
				};
			});
		}

		if (name === 'endDate') {
			setShiftDetails((prev: any) => {
				const updatedEndTime = prev.startTime
					? prev.startTime.add(2, 'hour')
					: null;
				return {
					...prev,
					endDate: value,
					endTime: updatedEndTime,
				};
			});
		}

		// if (name === 'defaultRegularHours') {
		// 	let maxRegularHours = '08:00';
		// 	if (shiftDetails.isForeMan === 'true') {
		// 		maxRegularHours = '12:00';
		// 	} else if (shiftDetails.isForeMan === null) {
		// 		return;
		// 	}

		// 	const valueMinutes = timeStringToMinutes(value);
		// 	const maxMinutes = timeStringToMinutes(maxRegularHours);
		// 	let updatedRegularHours = value;
		// 	let updatedOvertimeHours = shiftDetails.defaultOverTimeHours;

		// 	if (valueMinutes > maxMinutes) {
		// 		// If entered value exceeds max regular hours
		// 		updatedRegularHours = maxRegularHours;

		// 		// Calculate overtime hours
		// 		const overtimeMinutes = valueMinutes - maxMinutes;
		// 		updatedOvertimeHours = minutesToTimeString(overtimeMinutes);
		// 	}

		// 	// Directly call OnChange with the updated values to avoid double state update
		// 	const updatedShiftDetails = {
		// 		...shiftDetails,
		// 		defaultRegularHours: updatedRegularHours,
		// 		defaultOverTimeHours: updatedOvertimeHours,
		// 	};

		// 	setShiftDetails(updatedShiftDetails);

		// 	// Use the updated values for OnChange
		// 	OnChange(updatedRegularHours, 'defaultRegularHours');
		// 	OnChange(updatedOvertimeHours, 'defaultOverTimeHours');

		// 	return; // Prevent further OnChange call
		// }

		if (name === 'defaultRegularHours') {
			setInputDefaultRegularHours(value);

			// Only update the state without validation
			setShiftDetails((prev: any) => ({
				...prev,
				defaultRegularHours: value,
			}));

			OnChange(value, name);
			return;
		}

		if (name === 'defaultOverTimeHours') {
			// setShiftDetails((prev) => {...prev, endDate: value})
			setShiftDetails((prev: any) => {
				return {
					...prev,
					defaultOverTimeHours: value,
				};
			});
		}

		if (name === 'isForeMan') {
			setShiftDetails((prev: any) => {
				const maxRegularHours = value === 'true' ? '12:00' : '08:00';
				return {
					...prev,
					defaultRegularHours: maxRegularHours,
					defaultOverTimeHours: '00:00',
				};
			});
		}

		if (name !== 'defaultRegularHours') {
			OnChange(value, name);
		}
	};

	const OnChange = (
		value: string | number | null | string[] | Dayjs,
		key: string
	) => {
		setShiftDetails((prev) => {
			return {
				...prev,
				[key]: value,
			};
		});

		const checkFormError = validateFormData(
			{ [key]: value },
			{ ...formError }
		);
		setFormError(checkFormError);
	};

	const handleRegularHoursBlur = () => {
		let maxRegularHours = '08:00';
		if (shiftDetails.isForeMan === 'true') {
			maxRegularHours = '12:00';
		} else if (shiftDetails.isForeMan === null) {
			return;
		}

		const valueMinutes = timeStringToMinutes(inputDefaultRegularHours);
		const maxMinutes = timeStringToMinutes(maxRegularHours);
		let updatedRegularHours = inputDefaultRegularHours;
		let updatedOvertimeHours = shiftDetails.defaultOverTimeHours;

		if (valueMinutes > maxMinutes) {
			// If entered value exceeds max regular hours
			updatedRegularHours = maxRegularHours;

			// Calculate overtime hours
			const overtimeMinutes = valueMinutes - maxMinutes;
			updatedOvertimeHours = minutesToTimeString(overtimeMinutes);
		}

		// Update state with validated values
		setShiftDetails((prev: any) => ({
			...prev,
			defaultRegularHours: updatedRegularHours,
			defaultOverTimeHours: updatedOvertimeHours,
		}));

		// Trigger OnChange with updated values
		OnChange(updatedRegularHours, 'defaultRegularHours');
		OnChange(updatedOvertimeHours, 'defaultOverTimeHours');

		// Reset input state to the final value
		setInputDefaultRegularHours(updatedRegularHours);
	};

	const handleSubmit = async () => {
		let checkFormError = validateFormData(
			{
				...shiftDetails,
			},
			{ ...formError }
		);
		checkFormError = {
			...checkFormError,
			employeeIds: shiftDetails.employeeIds.length < 1 ? true : false,
			notes: false,
		};

		setFormError(checkFormError);

		if (hasFormError(checkFormError)) {
			return;
		} else {
			let _data: any = {
				...shiftDetails,
				startDate: formatToDateOnly(shiftDetails.startDate),
				endDate: formatToDateOnly(shiftDetails.endDate),
				defaultRegularHours: timeStringToMinutes(
					shiftDetails.defaultRegularHours
				),
				defaultOverTimeHours: timeStringToMinutes(
					shiftDetails.defaultOverTimeHours
				),
				isNightShift,
				isForeManShift: Boolean(shiftDetails.isForeMan),
			};

			delete _data['isForeMan'];

			if (!_data.notes) {
				delete _data['notes'];
			}

			if (!editShift) {
				try {
					setIsSubmitLoading(true);
					const apiRes = await shiftsApi.createShifts(_data);
					const successMessage =
						apiRes?.data?.message || 'Shift created successfully';
					toastText(successMessage, 'success');
					fetchShifts();
					handleCloseModal();
				} catch (error: any) {
					let message =
						'Something went wrong in fetching employee details';

					if (error.response.data.message) {
						message = error.response.data.message;
					}
					toastText(message, 'error');
				} finally {
					setIsSubmitLoading(false);
				}
			} else {
				try {
					setIsSubmitLoading(true);
					const apiRes = await shiftsApi.updateShift(
						editShift.id,
						_data
					);
					const successMessage =
						apiRes?.data?.message || 'Shift edit successfully';
					toastText(successMessage, 'success');
					fetchShifts();
					handleCloseModal();
				} catch (error: any) {
					const message =
						error?.response?.data?.message ||
						'Something went wrong in editing shift';
					toastText(message, 'error');
				} finally {
					setIsSubmitLoading(false);
				}
			}
		}
	};

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleCloseModal();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
		{
			text: `Save`,
			isLoading: isSubmitLoading,
			className: 'btn-blue',
			isSubmit: false,
			disabled: false,
			onclick: () => {
				handleSubmit();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
	];

	useEffect(() => {
		dispatch(fetchConstantDropdownAction({}));
	}, []);

	useEffect(() => {
		if (isModalOpen) {
			if (editShift) {
				setShiftDetails({
					...editShift,
					startDate: dayjs(editShift.startDate),
					endDate: dayjs(editShift.endDate),
					startTime: dayjs(editShift.startTime),
					endTime: dayjs(editShift.endTime),
					defaultRegularHours: minutesToTimeString(
						editShift.defaultRegularHours
					),
					defaultOverTimeHours: minutesToTimeString(
						editShift.defaultOverTimeHours
					),
					isForeMan: String(editShift.isForeMan),
				});
				setIsNightShift(editShift.isNightShift);
			} else {
				setShiftDetails({
					startDate: '',
					endDate: '',
					startTime: dayjs(new Date()),
					endTime: dayjs(new Date()),
					branchId: null,
					shiftName: '',
					employeeIds: [],
					supervisorId: null,
					notes: null,
					defaultRegularHours: '08:00',
					defaultOverTimeHours: '00:00',
					isForeMan: null,
				});
				setIsNightShift(false);
			}
			setFormError({
				startDate: false,
				endDate: false,
				startTime: false,
				endTime: false,
				branchId: false,
				shiftName: false,
				employeeIds: false,
				supervisorId: false,
				notes: false,
				defaultRegularHours: false,
				isForeMan: false,
				// defaultOverTimeHours: false,
			});
		}
		setIsDisabled(false);
		setHasError(false);
	}, [isModalOpen]);

	useEffect(() => {
		const fetchingAllHourlyEmployee = async () => {
			const response = await employeeApi.getHourlyEmployeeOptions({});

			if (response.data && Array.isArray(response.data.data)) {
				const formattedOptions = response.data.data.map((emp: any) => ({
					label: `${emp.employeeId} | ${emp.employeeName}`,
					value: emp.id,
					employeeBranchId: emp.employeeBranchId,
					isForeMan: emp.isForeMan,
				}));

				setEmployeeOptions(formattedOptions);
			} else {
				setEmployeeOptions([]);
			}
		};
		fetchingAllHourlyEmployee();
	}, []);

	useEffect(() => {
		if (!employeeOptions?.length) return;
		if (employeeOptions && employeeOptions.length > 0) {
			const filteredOptions =
				shiftDetails.branchId && shiftDetails.isForeMan !== null
					? employeeOptions.filter(
							(option: any) =>
								option.employeeBranchId ===
									shiftDetails.branchId &&
								option.isForeMan ===
									(shiftDetails.isForeMan === 'true')
					  )
					: [];

			setFilteredEmployees(filteredOptions);
		}
	}, [shiftDetails.branchId, shiftDetails.isForeMan, employeeOptions]);

	return (
		<>
			<Modal
				open={isModalOpen}
				// onOk={handleSubmit}
				onCancel={handleCloseModal}
				okText={'Save'}
				closable={false}
				footer={null}
				className="document__popup"
				width="1000px"
				maskClosable={true}
			>
				<div className={`${styles['modal']} modal`}>
					<div className={styles['modal-header']}>
						<Title level={4}>
							{editShift && isViewOnly ? (
								<span>{`Shift Details`}</span>
							) : (
								<span>
									<strong>{`${
										editShift ? 'Edit' : 'Add'
									} Shift`}</strong>
								</span>
							)}
						</Title>
						<div className={styles['modal-header-filter']}></div>
					</div>
					<div>
						<div className={styles['shift-scrollable-content']}>
							<Row
								className={styles['form-container-card']}
								gutter={50}
							>
								{isViewOnly && (
									<hr
										style={{
											width: '100%',
											marginBottom: '17px',
										}}
									/>
								)}
								<Col span={24} className={styles['col']}>
									<InputField
										name="shiftName"
										value={shiftDetails.shiftName}
										label="Shift Name"
										required={true}
										helperText="Shift name required"
										onChange={(value) => {
											handleChange(
												value,
												'shiftName',
												true
											);
										}}
										isError={formError.shiftName}
										disabled={isDisabled}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<DatePickerField
										name="startDate"
										value={shiftDetails.startDate}
										label="Shift Start Date"
										required={true}
										helperText="Shift start date required"
										onChange={(value) => {
											handleChange(
												value,
												'startDate',
												true
											);
										}}
										isError={formError.startDate}
										disabled={isDisabled}
										// disabledBeforeDates={dayjs().startOf(
										// 	'day'
										// )}
										disabledAfterDates={
											shiftDetails?.endDate
												? shiftDetails.endDate
												: ''
										}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<DatePickerField
										name="endDate"
										value={shiftDetails.endDate}
										label="Shift End Date"
										required={true}
										helperText="Shift end date required"
										onChange={(value) => {
											handleChange(
												value,
												'endDate',
												true
											);
										}}
										isError={formError.endDate}
										disabled={
											shiftDetails.startDate
												? false
												: true
										}
										disabledBeforeDates={
											shiftDetails.startDate
										}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<TimePickerField
										name="startTime"
										value={dayjs(shiftDetails.startTime)}
										label="Shift Schedule Start Time"
										required={true}
										helperText="Shift schedule start time required"
										onChange={(value) => {
											handleChange(
												dayjs(value),
												'startTime',
												true
											);
										}}
										isError={formError.startTime}
										disabled={
											!shiftDetails.startDate ||
											isDisabled
										}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<TimePickerField
										name="endTime"
										value={dayjs(shiftDetails.endTime)}
										label="Shift Schedule End Time"
										required={true}
										helperText="Shift schedule end time required"
										onChange={(value) => {
											handleChange(
												dayjs(value),
												'endTime',
												true
											);
										}}
										isError={formError.endTime}
										disabled={
											!shiftDetails.endDate || isDisabled
										}
										isViewOnly={isViewOnly}
										startTime={dayjs(
											shiftDetails.startTime
										)}
										placeholder=""
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<SelectDropdown
										placeholder="Select Branch"
										options={
											constantDropdownOptions?.branchCode
										}
										value={shiftDetails.branchId}
										onChange={(value: any) => {
											setFilteredEmployees([]);
											handleChange(
												value,
												'branchId',
												true
											);
										}}
										size="large"
										required={true}
										helperText="Branch is required"
										label="Branch"
										disabled={isDisabled}
										isError={formError.branchId}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<SelectDropdown
										placeholder="Select Job Grade"
										options={[
											{
												label: 'Security Guard',
												value: 'true',
											},
											{
												label: 'Other Job Grades',
												value: 'false',
											},
										]}
										value={shiftDetails.isForeMan}
										onChange={(value: any) => {
											setFilteredEmployees([]);
											handleChange(
												value,
												'isForeMan',
												true
											);
										}}
										size="large"
										required={true}
										helperText="Job grade is required"
										label="Job Grade"
										disabled={isDisabled}
										isError={formError.isForeMan}
										isViewOnly={isViewOnly}
									/>
								</Col>

								<Col span={12} className={styles['col']}>
									<SelectDropdown
										placeholder="Select Employee"
										// options={
										// 	shiftDetails.branchId
										// 		? constantDropdownOptions?.employees.filter(
										// 				(item: any) =>
										// 					item.employeeBranchId ===
										// 					shiftDetails.branchId
										// 		  )
										// 		: []
										// }
										options={filteredEmployees}
										// options={[]}
										mode="multiple"
										value={shiftDetails.employeeIds}
										onChange={(value: any) => {
											handleChange(
												value,
												'employeeIds',
												true
											);
										}}
										size="large"
										required={true}
										helperText="At least 1 employee required"
										label="Employees"
										disabled={isDisabled}
										isError={formError.employeeIds}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={12} className={styles['col']}>
									<SelectDropdown
										placeholder="Select Supervisor"
										options={constantDropdownOptions?.supervisors?.filter(
											(item: any) => item.label !== 'All'
										)}
										value={shiftDetails.supervisorId}
										onChange={(value: any) => {
											handleChange(
												value,
												'supervisorId',
												true
											);
										}}
										size="large"
										required={true}
										helperText="Supervisor required"
										label="Supervisor"
										disabled={isDisabled}
										isError={formError.supervisorId}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={8} className={styles['col']}>
									<InputShiftHoursField
										value={shiftDetails.defaultRegularHours}
										onChange={(value) => {
											handleChange(
												value,
												'defaultRegularHours',
												true
											);
										}}
										disabled={
											shiftDetails.isForeMan === null
										}
										onBlur={handleRegularHoursBlur}
										label="Standard Regular Hours"
										showLabel={true}
										required={true}
										name="defaultRegularHours"
										size="large"
										style={{
											width: '100px',
										}}
										isError={false}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={8} className={styles['col']}>
									<InputHoursField
										value={
											shiftDetails.defaultOverTimeHours
										}
										onChange={(value) => {
											handleChange(
												value,
												'defaultOverTimeHours',
												false
											);
										}}
										disabled={
											shiftDetails.isForeMan === null
										}
										label="Standard Over Time Hours"
										showLabel={true}
										required={false}
										name="defaultOverTimeHours"
										size="large"
										style={{
											width: '100px',
										}}
										isError={false}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={8} className={styles['col']}>
									<InputHoursField
										value={minutesToTimeString(
											timeStringToMinutes(
												shiftDetails.defaultRegularHours
											) +
												timeStringToMinutes(
													shiftDetails.defaultOverTimeHours
												)
										)}
										onChange={(value) => {}}
										disabled={true}
										label="Standard Total Hours"
										showLabel={true}
										required={false}
										name=""
										size="large"
										style={{
											width: '100px',
										}}
										isError={false}
										isViewOnly={isViewOnly}
									/>
								</Col>
								<Col span={24} className={styles['col']}>
									<Checkbox
										checked={isNightShift}
										onChange={(e) => {
											setIsNightShift(e.target.checked);
										}}
										className="night-shift-checkbox"
									>
										<p className="checkbox-container-label">
											Night Shift
										</p>
									</Checkbox>
								</Col>
								<Col span={24} className={styles['col']}>
									<InputField
										name="notes"
										value={shiftDetails.notes as string}
										label="Notes"
										required={false}
										helperText="Notes required"
										onChange={(value) => {
											handleChange(value, 'notes', false);
										}}
										isError={formError.notes}
										disabled={isDisabled}
										rows={2}
										isViewOnly={isViewOnly}
									/>
								</Col>
							</Row>
						</div>
						{!isViewOnly && (
							<Row className={styles['modal-buttons']}>
								<Buttons buttons={buttons} />
							</Row>
						)}
					</div>
				</div>
			</Modal>
		</>
	);
};

export default CreateShiftModal;
