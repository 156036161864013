import {
	CalendarOutlined,
	DollarOutlined,
	GiftOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { Modal, Space, Table, Tag, Tooltip } from 'antd';
import { timeLogsApi } from 'Api/timeLogs';
import Buttons from 'components/Global/Buttons';
import DatePickerField from 'components/Global/DatePicker';
import useDebounce from 'components/Global/Hooks/UseDebounce';
import SearchComponent from 'components/Global/SearchComponent';
import InputHoursField from 'components/InputHoursField';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
	minutesToTimeString,
	timeStringToMinutes,
	toastText,
} from 'utils/utils';
import styles from './index.module.scss';
import './index.scss';

const { Column } = Table;

const MonthlyTimeLogsModal = ({
	isOpen,
	handleCancel,
	fetchEmployeesTimeActivities,
}: any) => {
	const [employeeMonthlyHours, setEmployeeMonthlyHours] = useState<any>([]);
	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(30);
	const [isTimeLogsLoading, setIsTimeLogsLoading] = useState(false);
	const [isUpdateLoading, setIsUpdateLoading] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [date, setDate] = useState<any>(dayjs());
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const [isHoliday, setIsHoliday] = useState(false);
	const [holiday, setHoliday] = useState('');
	const [isSunday, setIsSunday] = useState(false);

	const tableChangeHandler = (pagination: any, filters: any, sorter: any) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
	};

	const handleSearch = (value: string) => {
		setSearchValue(value);
	};

	const debounceSearch = useDebounce(searchValue, 500);

	const handleSearchApi = async (value: string) => {
		//search api
	};

	const handleDate = (value: string) => {
		setDate(value);
	};

	// const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
	// 	let hours = timeStringToMinutes('00:00'); // Reset total hours to 0
	// 	let _data = employeeMonthlyHours;
	// 	const totalRow = employeeMonthlyHours.find(
	// 		(log: any) => log.employeeId === 'total'
	// 	);

	// 	_data = _data.map((log: any) => {
	// 		if (
	// 			newSelectedRowKeys.includes(log.employeeId) &&
	// 			log.employeeId !== 'total'
	// 		) {
	// 			hours += timeStringToMinutes(log.hours); // Add hours for selected employees
	// 			return {
	// 				...log,
	// 				disabled: false,
	// 				hours:
	// 					log.hours === '00:00'
	// 						? minutesToTimeString(hours)
	// 						: log.hours,
	// 			};
	// 		} else {
	// 			// Reset hours and disable unselected employees
	// 			return {
	// 				...log,
	// 				disabled: true,
	// 				totalHours: '00:00', // Reset total hours for unselected employees
	// 			};
	// 		}
	// 	});

	// 	// Recalculate total hours after updating the logs
	// 	_data.forEach((item: any) => {
	// 		hours += timeStringToMinutes(item.hours);
	// 	});

	// 	// Update the total row with the new total hours
	// 	if (totalRow) {
	// 		totalRow.hours = minutesToTimeString(hours);
	// 		_data.push(totalRow);
	// 	}

	// 	setEmployeeMonthlyHours(_data);
	// 	setSelectedRowKeys(newSelectedRowKeys);
	// };

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		const updatedData = employeeMonthlyHours.map((log: any) => {
			if (newSelectedRowKeys.includes(log.employeeId)) {
				return {
					...log,
					isDisable: false,
				};
			} else if (selectedRowKeys.includes(log.employeeId)) {
				return {
					...log,
					isDisable: true,
				};
			}
			return log;
		});

		// Update total hours row
		const totalRow = updatedData.find(
			(row: any) => row.employeeName === 'Total'
		);
		const rowsToSum = updatedData.filter(
			(row: any) =>
				row.employeeName !== 'Total' &&
				newSelectedRowKeys.includes(row.employeeId)
		);

		const totalHours = rowsToSum.reduce((acc: number, row: any) => {
			return acc + timeStringToMinutes(row.hours);
		}, 0);

		if (totalRow) {
			totalRow.hours = minutesToTimeString(totalHours);
		}

		setEmployeeMonthlyHours(updatedData);
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		getCheckboxProps: (record: any) => ({
			disabled: record.isTotal || record.isOnLeave || record.isPayrollRun,
		}),
	};

	const handleLogChange = (id: string, name: string, value: any) => {
		let hours = 0;

		const _data = employeeMonthlyHours.map((item: any, index: number) => {
			if (item.employeeId === id) {
				hours += timeStringToMinutes(value);
				return {
					...item,
					invalidHours: false,
					invalidTimeShiftHours: false,
					invalidOvertimeHours: false,
					[name]: value,
				};
			} else if (index === employeeMonthlyHours.length - 1) {
				return {
					...item,
					invalidHours: false,	
					hours: minutesToTimeString(hours),
				};
			} else {
				hours += isSunday ? 0 : timeStringToMinutes(item.hours);

				return {
					invalidHours: false,
					...item,
				};
			}
		});

		setEmployeeMonthlyHours(_data);
	};

	const fetchMonthlyTimeLogs = async () => {
		try {
			setIsTimeLogsLoading(true);
			const query = {
				date: dayjs(date).format('YYYY-MM-DD'),
				// jobGrade: selectedJobGrade,
				page: currentPage,
				pageSize: pageSize,
				search: debounceSearch,
			};
			const response = await timeLogsApi.getMonthlyTimeLogs(query);

			setTotalRecords(response.data.total);

			setEmployeeMonthlyHours(response.data.data?.data);

			const defaultSelectedKeys = response.data.data.data
				.filter((item: any) => !item.isTotal)
				.map((item: any) => item.employeeId);

			setSelectedRowKeys(defaultSelectedKeys);
			setIsSunday(response.data.data?.isSunday);
			setIsHoliday(response.data.data?.isHoliday);
			setHoliday(response.data.data?.holidayName);
		} catch (err) {
		} finally {
			setIsTimeLogsLoading(false);
		}
	};

	const validateEmployeeHours = () => {
		let invalidData = false;

		const _data = employeeMonthlyHours.map((item: any) => {
			let data = { ...item };

			if (item.totalHours === '00:00') {
				data['invalidTotalHours'] = true;
			}

			if (data.invalidTotalHours) {
				invalidData = true;
			}

			return data;
		});

		setEmployeeMonthlyHours(_data);

		return invalidData;
	};

	const handleSubmit = async () => {
		try {
			setIsUpdateLoading(true);
			const _data = employeeMonthlyHours.filter(
				(item: any) => !item.isTotal && !item.isDisable
			);

			const data = {
				date: dayjs(date).format('YYYY-MM-DD'),
				employeeHoursData: _data.map((log: any) => ({
					...log,
					employeeId: log.employeeId,
					totalHours: timeStringToMinutes(log.hours),
				})),
			};

			const isInvalid = validateEmployeeHours();
			if (!isInvalid) {
				await timeLogsApi.createMonthlyTimeLogs(data);
				fetchEmployeesTimeActivities();
			}

			toastText('Monthly Attendance updated successfully', 'success');
			handleCancel();
		} catch (error: any) {
			let message = 'Something went wrong in submitting Daily Attendance';
			if (error.response?.data?.message) {
				message = error.response.data.message;
			}
			toastText(message, 'error');
		} finally {
			setIsUpdateLoading(false);
			fetchMonthlyTimeLogs();
		}
	};

	const buttons = [
		{
			text: 'Cancel',
			isLoading: false,
			className: 'btn-cancel',
			isSubmit: false,
			disabled: isUpdateLoading || isTimeLogsLoading,
			onclick: () => {
				handleCancel();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
		{
			text: 'Submit',
			isLoading: isUpdateLoading,
			className: 'btn-blue',
			isSubmit: false,

			disabled: isUpdateLoading || isTimeLogsLoading,
			onclick: () => {
				handleSubmit();
			},
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
		},
	];

	useEffect(() => {
		fetchMonthlyTimeLogs();
	}, [date, pageSize, currentPage, debounceSearch]);

	return (
		<Modal
			open={isOpen}
			closable={false}
			style={{ minWidth: '1000px' }}
			rootClassName="monthly-logs-root"
			onCancel={handleCancel}
			className="monthly-modal"
			centered
			footer={() => {
				return (
					<div className="modal">
						<div className={styles['modal-header']}>
							<Space>
								<SearchComponent
									className={styles['employee-header-item']}
									placeHolder="Search employee..."
									prefixIcon={<SearchOutlined />}
									handleChange={handleSearch}
									handleChangeApi={handleSearchApi}
									value={searchValue}
									size="large"
								/>
							</Space>
							<div className={styles['modal-header-filter']}>
								{isSunday && (
									<Tooltip title="All hours on Sunday are considered overtime">
										<Tag
											style={{
												cursor: 'pointer',
												fontSize: '14px',
												padding: '8px',
											}}
											color="#584495"
										>
											<div
												style={{
													display: 'flex',
													gap: '7px',
												}}
											>
												<span>
													<CalendarOutlined />
												</span>
												<span>Sunday</span>
											</div>
										</Tag>
									</Tooltip>
								)}
								{isHoliday && holiday && (
									<Tooltip title="All hours on Holiday are considered overtime">
										<Tag
											style={{
												cursor: 'pointer',
												fontSize: '14px',
												padding: '8px',
											}}
											color="#584495"
										>
											<div
												style={{
													display: 'flex',
													gap: '7px',
												}}
											>
												<span>
													<GiftOutlined />
												</span>
												<span>{holiday}</span>
											</div>
										</Tag>
									</Tooltip>
								)}

								<DatePickerField
									name="date"
									onChange={handleDate}
									required={false}
									value={date && dayjs(date)}
									isError={false}
									disabled={isTimeLogsLoading}
									placeholder="Select Date"
								/>
							</div>
						</div>
						<div className={styles['modal-body']}>
							<Table
								dataSource={employeeMonthlyHours}
								scroll={{ y: 'calc(100vh - 360px)' }}
								pagination={{
									total: totalRecords,
									current: currentPage,
									pageSize,
									showSizeChanger: false,
									pageSizeOptions: [10, 20, 50, 100, 200],
								}}
								onChange={tableChangeHandler}
								rowKey={(record) => record.employeeId}
								loading={isTimeLogsLoading}
								rowSelection={rowSelection}
								rowClassName={(record) =>
									record.isTotal ? 'total-row' : ''
								}
							>
								<Column
									title="Employee Name | Code"
									dataIndex="employeeName"
									key="employeeName"
									className="bg-white"
									width="50%"
									render={(text: string, record: any) => (
										<Tooltip
											title={
												record.isOnLeave
													? 'On leave'
													: ''
											}
										>
											<span
												style={{
													color: record.isOnLeave
														? 'red'
														: 'inherit',
													display: 'flex',
													alignItems: 'center',
													gap: '0.5rem',
												}}
											>
												{record.isPayrollRun &&
													record?.payrollStatus && (
														<Tooltip
															title={
																record.payrollStatus ===
																	'APPROVED' ||
																record.payrollStatus ===
																	'FINALIZED'
																	? 'Payroll is Approved/Finalized'
																	: 'Payroll is Pending'
															}
														>
															<DollarOutlined
																style={{
																	color:
																		record.payrollStatus ===
																			'APPROVED' ||
																		record.payrollStatus ===
																			'FINALIZED'
																			? 'green'
																			: 'grey',
																}}
															/>
														</Tooltip>
													)}
												{text}
											</span>
										</Tooltip>
									)}
								/>
								<Column
									title="Overtime Hours"
									dataIndex="hours"
									key="hours"
									className="bg-white"
									width="30%"
									render={(text: any, data: any) => {
										return data.isTotal ? (
											isSunday ||
											isHoliday ||
											data.isOnLeave ? (
												'00:00'
											) : (
												text
											)
										) : (
											<InputHoursField
												value={
													isHoliday || data.isOnLeave
														? '00:00'
														: text
												}
												onChange={(value) => {
													handleLogChange(
														data.employeeId,
														'hours',
														value
													);
												}}
												disabled={
													data.isOnLeave ||
													data.isPayrollRun ||
													isHoliday ||
													data.isDisable
												}
												label="Total Hours"
												required={true}
												name="hours"
												isError={data.invalidTotalHours}
												showLabel={false}
												size="middle"
												style={{
													width: '100px',
												}}
											/>
										);
									}}
								/>
							</Table>
						</div>
						<div>
							<Buttons buttons={buttons} />
						</div>
					</div>
				);
			}}
		>
			{/* Any additional modal content */}
		</Modal>
	);
};

export default MonthlyTimeLogsModal;
