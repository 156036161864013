import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';
import RefreshComponent from 'components/Global/RefreshComponent';
import SearchComponent from 'components/Global/SearchComponent';
import { useContext } from 'react';
import styles from './index.module.scss';

type Props = {
	searchValue: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => void;
	button: any;
	isLoading: boolean;
	fetchAllEarnings: () => void;
};

const EarningHeader = (props: Props) => {
	const {
		searchValue,
		handleSearch,
		handleSearchApi,
		button,
		isLoading,
		fetchAllEarnings,
	} = props;

	const permissions = useContext(PermissionContext);

	return (
		<div className={styles['employee-header']}>
			<Space>
				<SearchComponent
					className={styles['employee-header-item']}
					placeHolder="Search code and acc no..."
					prefixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchValue}
					size="large"
				/>
			</Space>
			<Space>
				<Tooltip title="Refresh all the Earning Codes">
					<SyncOutlined
						style={{
							fontSize: '20px',
							cursor: isLoading ? 'not-allowed' : 'pointer',
							color: '#584495',
							transition: 'transform 0.3s',
							marginRight: '10px',
						}}
						onClick={!isLoading ? fetchAllEarnings : undefined}
					/>
				</Tooltip>

				{permissions.allowedPermissions.includes(
					'Add_Earnings_Code'
				) && <Buttons buttons={button} />}
			</Space>
		</div>
	);
};

export default EarningHeader;
