import { SearchOutlined, SyncOutlined } from '@ant-design/icons';
import { Select, Space, Tooltip } from 'antd';
import SearchComponent from 'components/Global/SearchComponent';
import styles from './index.module.scss';
import { useContext } from 'react';
import { PermissionContext } from 'components/Global/AuthLayout';
import Buttons from 'components/Global/Buttons';

type Props = {
	button: any;
	searchText: string;
	handleSearch: (value: string) => void;
	handleSearchApi: (value: string) => void;
	leaveTypeFilterValue: string;
	handleLeaveTypeFilter: (value: string) => void;
	isLoading: any;
	fetchLeaveManagementData:any;
};

const LeaveManagementHeader = (props: Props) => {
	const {
		button,
		searchText,
		handleSearch,
		handleSearchApi,
		handleLeaveTypeFilter,
		leaveTypeFilterValue,
		fetchLeaveManagementData,
		isLoading
	} = props;
	const permissions = useContext(PermissionContext);

	return (
		<div className={styles['employee-header']}>
			<Space>
				<SearchComponent
					className={styles['employee-header-item']}
					placeHolder="Search here..."
					prefixIcon={<SearchOutlined />}
					handleChange={handleSearch}
					handleChangeApi={handleSearchApi}
					value={searchText}
					size="large"
				/>
				<Select
					className={styles['employee-header-select']}
					value={leaveTypeFilterValue}
					options={[
						{ label: 'All', value: '' },
						{ label: 'Paid Leave', value: 'PAID' },
						{
							label: 'Unpaid Leave',
							value: 'UNPAID',
						},
					]}
					onChange={(value) => {
						handleLeaveTypeFilter(value);
					}}
					size="large"
					placeholder="Select by Leave Type"
				/>
			</Space>

			<Space>
				<div className={styles['employee-header__buttons']}>
					<Tooltip title="Refresh all the Leave Management">
						<SyncOutlined
							style={{
								fontSize: '20px',
								cursor: isLoading ? 'not-allowed' : 'pointer',
								color: '#584495',
								transition: 'transform 0.3s',
								marginRight: '10px',
							}}
							onClick={
								!isLoading
									? fetchLeaveManagementData
									: undefined
							}
						/>
					</Tooltip>
					<Buttons buttons={button} />
				</div>
			</Space>
		</div>
	);
};

export default LeaveManagementHeader;
