import dayjs from 'dayjs';
import { TablePagination, TableSorter } from 'interfaces/global.interface';
import { useEffect, useState } from 'react';
import { AddSvg } from 'utils/svgs';
import { toastText } from 'utils/utils';
import styles from './index.module.scss';
import LeaveManagementTable from './Table';
import LeaveManagementHeader from './Header';
import { leaveManagementApi } from 'Api/masters/leave-management';
import AddLeaveManagementModal from './AddLeaveManagementModal';
import ConfirmDelete from 'components/Global/confirmDeleteModel';
import LeaveHistoryModal from './LeaveHistoryModal';
import useDebounce from 'components/Global/Hooks/UseDebounce';

const LeaveManagementComponent = () => {
	const [leaveManagementData, setLeaveManagementData] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const [totalRecords, setTotalRecords] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(10);
	const [sortField, setSortField] = useState('');
	const [sortOrder, setSortOrder] = useState('');
	const [searchText, setSearchText] = useState('');
	const [leaveTypeFilterValue, setLeaveTypeFilterValue] = useState('');

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [deleteLeaveManagementId, setDeleteLeaveManagementId] = useState<
		string | null
	>(null);
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

	const [isAddLeaveManagementModalOpen, setIsAddLeaveManagementModalOpen] =
		useState(false);
	const [isEdit, setIsEdit] = useState<any>(null);
	const [editData, setIsEditData] = useState<any>(null);

	const [configRuleModal, setConfigRuleModal] = useState(false);
	const [date, setDate] = useState<any>(dayjs());

	const [historyModal, setHistoryModal] = useState<boolean>(false);
	const [historyLeaveData, setHistoryLeaveData] = useState<any>({});
const debouncedSearchValue = useDebounce(searchText, 500);
	const handleSearch = (value: string) => {
		setSearchText(value);
	};
	const handleSearchApi = async (value: string) => {
		//search api
	};
	const handleLeaveTypeFilter = (value: string) => {
		setLeaveTypeFilterValue(value);
	};

	const cancelDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setDeleteLeaveManagementId(null);
	};

	const showAddLeaveManagementModal = () => {
		setIsAddLeaveManagementModalOpen(true);
	};

	const handleEdit = (data: any) => {
		setIsEdit(true);
		setIsEditData(data);
		showAddLeaveManagementModal();
	};

	const closeAllModals = () => {
		setIsAddLeaveManagementModalOpen(false);
		setIsEdit(false);
		setIsEditData(null);
		setConfigRuleModal(false);
	};

	const openConfigRuleModal = () => {
		setIsAddLeaveManagementModalOpen(false);
		setConfigRuleModal(true);
	};
	const showDeleteModal = () => {
		setIsDeleteModalOpen(true);
	};

	const openHistoryModal = (data: any) => {
		setHistoryModal(true);
		setHistoryLeaveData(data);
	};

	const handleHistoryModal = () => {
		setHistoryModal(false);
		setHistoryLeaveData({});
	};

	const deleteHandler = async () => {
		if (deleteLeaveManagementId) {
			try {
				setIsDeleteLoading(true);
				const response = await leaveManagementApi.deleteLeaveManagement(
					deleteLeaveManagementId
				);
					let message =
						response?.data?.message || 'Leave Management Deleted successfully';
					toastText(message, 'success');
				cancelDeleteModal();
				fetchLeaveManagementData();
			} catch (error: any) {
				const errorMessage =
					error.response?.data?.message ||
					'Something went wrong in delete leave Type';
				toastText(errorMessage, 'error');
			} finally {
				setIsDeleteLoading(false);
			}
		}
	};

	const buttons = [
		{
			text: 'Add Leave',
			isLoading: false,
			className: 'btn-blue',
			icon: <AddSvg />,
			fontSize: '1.8rem',
			minWidth: '12rem',
			minHeight: '4rem',
			isSubmit: true,
			onclick: () => {
				setIsAddLeaveManagementModalOpen(true);
			},
			disabled: isLoading,
		},
	];
	const tableChangeHandler = (
		pagination: TablePagination,
		filters: any,
		sorter: TableSorter
	) => {
		setTotalRecords(pagination.total);
		setCurrentPage(pagination.current);
		setPageSize(pagination.pageSize);
		setSortOrder(
			sorter?.order === 'ascend' ? 'asc' : sorter?.order ? 'desc' : ''
		);
		setSortField(sorter.field);
	};

	const fetchLeaveManagementData = async () => {
		try {
			const query = {
				page: currentPage,
				searchText: debouncedSearchValue,
				sortBy: sortField,
				sortOrder: sortOrder,
				pageSize: pageSize,
				leaveFilter: leaveTypeFilterValue,
			};
			setIsLoading(true);
			const leaveManagement = await leaveManagementApi.getLeaveManagement(
				query
			);

			setLeaveManagementData(leaveManagement.data.data);
			setTotalRecords(leaveManagement.data.total);
		} catch (err: any) {
			let message = 'Something went wrong in fetching LeaveManagement.';

			if (err.response?.data?.message) {
				message = err.response.data.message;
			}

			toastText(message, 'error');
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		fetchLeaveManagementData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentPage,
		date,
		sortField,
		sortOrder,
		pageSize,
		debouncedSearchValue,
		leaveTypeFilterValue,
	]);

	return (
		<>
			<div>
				<div className={styles['employee-container']}>
					<LeaveManagementHeader
						button={buttons}
						searchText={searchText}
						handleSearch={handleSearch}
						handleSearchApi={handleSearchApi}
						leaveTypeFilterValue={leaveTypeFilterValue}
						handleLeaveTypeFilter={handleLeaveTypeFilter}
						fetchLeaveManagementData={fetchLeaveManagementData}
						isLoading={isLoading}
					/>
					<LeaveManagementTable
						leaveManagementData={leaveManagementData}
						totalRecords={totalRecords}
						currentPage={currentPage}
						pageSize={pageSize}
						isLoading={isLoading}
						handleEditData={handleEdit}
						tableChangeHandler={tableChangeHandler}
						setDeleteLeaveManagementId={setDeleteLeaveManagementId}
						fetchRecords={fetchLeaveManagementData}
						showDeleteModal={showDeleteModal}
						openHistoryModal={openHistoryModal}
					/>
				</div>
			</div>
			<AddLeaveManagementModal
				open={isAddLeaveManagementModalOpen}
				handleCancel={closeAllModals}
				isEdit={isEdit}
				editData={editData}
				openConfigRuleModal={openConfigRuleModal}
				configRuleModal={configRuleModal}
				fetchLeaveManagementData={fetchLeaveManagementData}
			/>{' '}
			<ConfirmDelete
				isModalOpen={isDeleteModalOpen}
				handleCancel={cancelDeleteModal}
				deleteHandler={deleteHandler}
				isLoading={isDeleteLoading}
				handleOk={cancelDeleteModal}
			/>
			<LeaveHistoryModal
				isModalOpen={historyModal}
				handleCancel={handleHistoryModal}
				historyLeaveData={historyLeaveData}
			/>
		</>
	);
};

export default LeaveManagementComponent;
